import React, { useEffect, useState } from 'react';
import { Card, CardBody, GridContainer, GridItem, Table } from "../../components";
import CustomInput from "../../components/CustomInput/CustomInput";
import { convertDate, WebService } from "../../components/WebService";
import Success from "../../components/Typography/Success";
import { AddCircleOutline, ArrowBack, Close, FindInPage, Block } from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import { Backdrop, Fade, ListItemText, makeStyles, Modal, Tooltip } from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";
import { formataCPFCNPJ, formatoDinheiro, isValidDate, somenteNumero } from "../../components/Funcoes";
import api from "../../components/api";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import styles from "../../assets/jss/material-dashboard-pro-react/components/tasksStyle";
import {
  grayColor,
  hexToRgb,
  infoColor,
  primaryColor,
  successColor
} from "../../assets/jss/material-dashboard-pro-react";
import Swal from "sweetalert2";
import Info from "../../components/Typography/Info";
import Warning from "../../components/Typography/Warning";
import Danger from "../../components/Typography/Danger";
import { checkBeta } from "../BetaControll";
import DotLoader from "react-spinners/DotLoader";
import { AutoAwesome } from "../../assets/img/icons";
import {useStyles} from "../NewChat/TabsInfo/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";

const statusType = {
  active: <Success><strong>Ativo</strong></Success>,
  canceled: <Danger><strong>Cancelado</strong></Danger>,
};

const periodType = {
  monthly: 'Mensal'
};

const invoiceType = {
  prepaid: 'Pré-Pago'
};

const discountType = {
  percentage: {
    name: 'Percentual',
    function: v => parseFloat(v.toString()).toFixed(1) + '%'
  },
  fixed: {
    name: 'Fixo',
    function: v => formatoDinheiro(Number(v.toString()))
  }
};

const useStyles1 = makeStyles({ ...dashboardStyle, ...styles });

const ClientConfiguration = ({ history, computedMatch }) => {
  const classes = { ...useStyles1(), ...useStyles() };

  const [ companyId, setCompanyId ] = useState(null);
  const [ subscriptions, setSubscriptions ] = useState([]);
  const [ company, setCompany ] = useState({
    name: '',
    vat: '',
    address: { uf: '', zip: '', city: '', number: 0, street: '', neighborhood: '' }
  });
  const [ users, setUsers ] = useState([]);
  const [ activeSubscription, setActiveSubscription ] = useState(null);
  const [ subscriptionTab, setSubscriptionTab ] = useState('subscription');
  const [ activeTab, setActiveTab ] = useState(0);
  const [ systemProducts, setSystemProducts ] = useState([]);

  const [ modalAddProduct, setModalAddProduct ] = useState({ idSubscription: null, idProduct: 0, idPlan: 0, idModule: 0 });

  // useEffect(() => {
  //   if(modalAddProduct.idSubscription === null)
  // }, [modalAddProduct]);

  useEffect(() => {
    setActiveSubscription(null);
  }, [ activeTab ]);


  const getSystemProducts = async () => {
    const { ok, products } = await WebService('/admin/getSystemProducts');
    if (ok) setSystemProducts(products);
  };

  const getCompany = async id => {
    const { ok, users, company } = await WebService('/admin/getClient', { id: id || companyId });
    if (ok) {
      setCompany(company);
      setUsers(users);
    }
  };

  const getCompanySubscriptions = async id => {
    const { ok, subscriptions } = await WebService('/admin/getSubscription', { id: id || companyId });
    if (ok) setSubscriptions(subscriptions);
  };

  const cancelInvoice = async id => {
    const { value } = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente cancelar esta fatura do cliente?',
      // text: `${file_link_nfse} ${file_link_nfse_xml}`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    await axios.delete(`https://api.hustapp.com/admin/invoice/${id}`);

    await Swal.fire({ icon: "success", title: 'Fatura cancelada com sucesso!' });
    getCompanySubscriptions();
  };

  const cancelSubscription = async id => {
    const { value } = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente cancelar esta assinatura do cliente?',
      text: `Todas as faturas pendentes serão canceladas e o cliente não será mais faturado.`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    await axios.delete(`https://api.hustapp.com/admin/subscription/${id}`);

    await Swal.fire({ icon: "success", title: 'Assinatura cancelada com sucesso!' });
    getCompanySubscriptions();
  };

  const sendInvoice = async ({ file_link, file_link_nfse, file_link_nfse_xml, value, date_due }) => {
    const { value: op, dismiss } = await Swal.fire({
      icon: "question",
      title: 'Enviar fatura para cliente.',
      // text: `${file_link_nfse} ${file_link_nfse_xml}`,
      showCancelButton: true,
      cancelButtonText: 'Enviar NFSe',
      confirmButtonText: 'Enviar fatura'
    });

    // noteURL = `https://palmasto.webiss.com.br/externo/nfse/visualizar/${companyEmitter.vat}/${verificationCode}/${number}`;
    // https://palmasto.webiss.com.br/externo/nfse/visualizar/42784960000130/2WRG-PYKY/202400000000025
    const { value: phone } = await Swal.fire({
      icon: "question",
      title: 'Qual o número de telefone para enviar?',
      input: "text",
      confirmButtonText: 'Enviar'
    });

    const message = {
      type: 'document',
      caption: '',
      file: { uuid: '' },
      called: 'without',
      connection: { uuid: 'a1306c60-3ecf-459c-9678-cb9afe95201d' },
      contact: { phone }
    };

    if (op === true) {
      message.caption = `Segue fatura conforme solicitado!\n\n*Vencimento:* \`\`\`${convertDate(date_due)}\`\`\`\n*Valor:* \`\`\`${formatoDinheiro(value)}\`\`\``;
      message.file.uuid = file_link.split('/').slice(-1)[0].split('.')[0];
      await api.post('/message/send', message);
      await Swal.fire({ icon: 'success', title: 'Fatura enviada com sucesso!' });
    }
    if (dismiss === 'cancel') {
      if (file_link_nfse) {
        message.caption = `Segue o PDF da Nota Fiscal conforme solicitado!`;
        message.file.uuid = file_link_nfse.split('/').slice(-1)[0].split('.')[0];
        await api.post('/message/send', message);
      }
      if (file_link_nfse_xml) {
        message.caption = `Segue o XML da Nota Fiscal conforme solicitado!`;
        message.file.uuid = file_link_nfse_xml.split('/').slice(-1)[0].split('.')[0];
        await api.post('/message/send', message);
      }
      await Swal.fire({ icon: 'success', title: 'Nota Fiscal enviada com sucesso!' });
    }
  };

  const searchCompanyVat = async vat => {
    vat = somenteNumero(vat);
    if (!vat || vat.length !== 14) {
      Swal.fire({ icon: "warning", title: 'É necessário digitar um CNPJ válido' });
      return;
    }
    try {
      Swal.fire({ title: "Carregando", allowOutsideClick: false });
      Swal.showLoading();
      const { data } = await api.get(`https://brasilapi.com.br/api/cnpj/v1/${vat}`);
      const {
        razao_social,
        qsa,
        uf,
        cep,
        bairro,
        numero,
        logradouro,
        municipio,
        cnae_fiscal,
        ddd_telefone_1,
        ddd_telefone_2
      } = data;

      setCompany({
        vat: formataCPFCNPJ(vat),
        name: razao_social,
        address: {
          uf,
          zip: cep,
          city: municipio,
          number: somenteNumero(numero) || 0,
          street: logradouro,
          neighborhood: bairro
        }
      });

      // qsa -> nome_socio, cnpj_cpf_do_socio
    } finally {
      Swal.hideLoading();
      Swal.close();
    }
  };

  useEffect(() => {
    const { params } = computedMatch;
    if (params.id && !isNaN(Number(params.id))) {
      const id = Number(params.id);
      setCompanyId(id);
      getCompany(id);
      getCompanySubscriptions(id);
    }
    getSystemProducts();
  }, [ computedMatch ]);

  return (
    <GridContainer>
      {/**************************************************************************************************************/}
      <Modal
        className={classes.modal}
        open={!!modalAddProduct.idSubscription}
        onClose={() => setModalAddProduct({idSubscription: null, idProduct: 0, idPlan: 0, idModule: 0})}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 200 }}
      >
        <Fade in={!!modalAddProduct.idSubscription}>
          <div className={classes.paperModal} style={{ border: 'none', minWidth: 600 }}>
            <h4 style={{ fontFamily: 'NunitoRegular', fontSize: 30, marginBottom: 0, color: primaryColor[0] }}>
              Adicionar produto/módulos
            </h4>
            <hr
              style={{ borderBottom: `4px solid ${primaryColor[0]}`, width: '40%', float: 'left', marginTop: 0, marginBottom: 0 }}
            />
            <br/><br/>
            <div style={{ position: 'relative' }}>
              <GridContainer>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  {/*systemProducts*/}
                  <InputLabel className={classes.label} style={{marginBottom: -10}}>
                    Produto
                  </InputLabel>
                  <br/>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    // disabled={!tempoMaxBot}
                  >
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      value={Number(modalAddProduct.idProduct)}
                      onChange={({ target: { value } }) => setModalAddProduct({ ...modalAddProduct, idProduct: Number(value), idModule: 0, idPlan: 0 })}
                      inputProps={{ name: "simpleSelect", id: "simple-select" }}
                    >
                      <MenuItem disabled classes={{ root: classes.selectMenuItem }} value={0}>
                        Selecione o produto
                      </MenuItem>
                      {systemProducts.map(prop => (
                        <MenuItem
                          classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                          value={Number(prop.id)}
                        >
                          {/*<ListItemText primary={prop.name} secondary={prop.price} />*/}
                          {prop.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  {modalAddProduct.idProduct ? (
                    <div>
                      <InputLabel className={classes.label} style={{ marginBottom: -10 }}>
                        Item
                      </InputLabel>
                      <br/>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        // disabled={!tempoMaxBot}
                      >
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={Number(modalAddProduct.idPlan)}
                          onChange={({ target: { value } }) => setModalAddProduct({...modalAddProduct, idPlan: Number(value), idModule: 0 })}
                          inputProps={{ name: "simpleSelect", id: "simple-select" }}
                        >
                          <MenuItem disabled classes={{ root: classes.selectMenuItem }} value={0}>
                            Selecione o Plano
                          </MenuItem>
                          {systemProducts[systemProducts.map(prop => prop.id).indexOf(modalAddProduct.idProduct)].items.map(prop => (
                            <MenuItem
                              classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                              value={Number(prop.id)}
                            >
                              <ListItemText primary={prop.name} secondary={formatoDinheiro(prop.price)} />
                              {/*{prop.name}*/}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : ''}
                </GridItem>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  {modalAddProduct.idPlan && systemProducts[systemProducts.map(prop => prop.id).indexOf(modalAddProduct.idProduct)]
                    .items[
                    systemProducts[systemProducts.map(prop => prop.id).indexOf(modalAddProduct.idProduct)]
                      .items.map(prop => prop.id).indexOf(modalAddProduct.idPlan)
                    ].modules.length ? (
                    <div>
                      <InputLabel className={classes.label} style={{ marginBottom: -10 }}>
                        Módulo
                      </InputLabel>
                      <br/>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        // disabled={!tempoMaxBot}
                      >
                        <Select
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={Number(modalAddProduct.idModule)}
                          onChange={({ target: { value } }) => setModalAddProduct({...modalAddProduct, idModule: Number(value)})}
                          inputProps={{ name: "simpleSelect", id: "simple-select" }}
                        >
                          <MenuItem disabled classes={{ root: classes.selectMenuItem }} value={0}>
                            Selecione o Módulo
                          </MenuItem>
                          {
                            systemProducts[systemProducts.map(prop => prop.id).indexOf(modalAddProduct.idProduct)]
                            .items[
                              systemProducts[systemProducts.map(prop => prop.id).indexOf(modalAddProduct.idProduct)]
                              .items.map(prop => prop.id).indexOf(modalAddProduct.idPlan)
                            ].modules.map(prop => (
                            <MenuItem
                              classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                              value={Number(prop.id)}
                            >
                              <ListItemText primary={prop.name} secondary={formatoDinheiro(prop.price)} />
                              {/*{prop.name}*/}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : ''}
                </GridItem>
              </GridContainer>
            </div>
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Button style={{ borderRadius: '24px 24px 24px 0px' }} onClick={() => setModalAddProduct({idSubscription: null, idProduct: 0, idPlan: 0, idModule: 0})}>
                Cancelar
              </Button>
              <div>
                <Button
                  color={'hust'}
                  style={{ borderRadius: '24px 24px 0px 24px' }}
                  onClick={async () => {
                    const { idSubscription, idProduct, idPlan, idModule } = modalAddProduct;
                    if (!idProduct || !idPlan) {
                      return Swal.fire({
                        icon: "warning",
                        title: 'É necessário selecionar um produto e um plano para cadastrar.'
                      });
                    }
                    const { value } = await Swal.fire({
                      icon: "question",
                      title: 'Deseja confirmar o cadastro do próduto no cliente?',
                      showCancelButton: true,
                      confirmButtonText: 'Sim',
                      cancelButtonText: 'Não'
                    });

                    if (!value) return;

                    // * * * * * * * *
                    const {ok} = await WebService('/admin/addProductSubscription', {
                      id_subscription: idSubscription,
                      id_product: idModule || idPlan
                    }, 'POST');
                    if (ok) {
                      await Swal.fire({ icon: "success", title: 'Produto/Módulo cadastrado com sucesso!' });
                      getCompanySubscriptions(companyId);
                      setModalAddProduct({idSubscription: null, idProduct: 0, idModule: 0, idPlan: 0});
                    }
                  }}
                >
                  Adicionar
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {/**************************************************************************************************************/}
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={'primary'} style={{ borderRadius: 20 }} onClick={() => history.push('/admin')} simple>
          <ArrowBack/> Voltar
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem lg={3} md={4} sm={6} xs={12}>
                <CustomInput
                  labelText={'CNPJ'}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    type: "text",
                    disabled: !!companyId,
                    onChange: ({ target }) => setCompany({ ...company, vat: target.value }),
                    // value: formataCPFCNPJ(company.vat)
                    value: company.vat,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={'Buscar dados'}>
                          <Icon
                            onClick={() => searchCompanyVat(company.vat)}
                            style={{ cursor: 'pointer', color: primaryColor[0] }}
                          >
                            {companyId ? 'youtube_searched_for' : 'search'}
                          </Icon>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem lg={9} md={8} sm={6} xs={12}>
                <CustomInput
                  labelText={'Nome'}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    type: "text",
                    onChange: ({ target }) => setCompany({ ...company, name: target.value }),
                    value: company.name
                  }}
                />
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <hr/>
                <GridContainer>
                  <GridItem lg={2} md={3} sm={4} xs={6}>
                    <CustomInput
                      labelText={'CEP'}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "number",
                        onChange: ({ target: { value } }) => setCompany({
                          ...company,
                          address: { ...company.address, zip: value }
                        }),
                        value: company.address.zip,
                      }}
                    />
                  </GridItem>
                  <GridItem lg={2} md={3} sm={6} xs={12}>
                    <CustomInput
                      labelText={'Cidade'}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        onChange: ({ target: { value } }) => setCompany({
                          ...company,
                          address: { ...company.address, city: value }
                        }),
                        value: company.address.city
                      }}
                    />
                  </GridItem>
                  <GridItem lg={1} md={2} sm={3} xs={12}>
                    <CustomInput
                      labelText={'UF'}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        onChange: ({ target: { value } }) => setCompany({
                          ...company,
                          address: { ...company.address, uf: value }
                        }),
                        value: company.address.uf
                      }}
                    />
                  </GridItem>
                  <GridItem lg={3} md={3} sm={6} xs={12}>
                    <CustomInput
                      labelText={'Rua'}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        onChange: ({ target: { value } }) => setCompany({
                          ...company,
                          address: { ...company.address, street: value }
                        }),
                        value: company.address.street
                      }}
                    />
                  </GridItem>
                  <GridItem lg={3} md={3} sm={6} xs={12}>
                    <CustomInput
                      labelText={'bairro'}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "text",
                        onChange: ({ target: { value } }) => setCompany({
                          ...company,
                          address: { ...company.address, neighborhood: value }
                        }),
                        value: company.address.neighborhood
                      }}
                    />
                  </GridItem>
                  <GridItem lg={1} md={2} sm={3} xs={12}>
                    <CustomInput
                      labelText={'Número'}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "number",
                        onChange: ({ target: { value } }) => setCompany({
                          ...company,
                          address: { ...company.address, number: value }
                        }),
                        value: company.address.number
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              {/*<GridItem lg={12} md={12} sm={12} xs={12}>
                <hr/>
                {[
                  {name: 'Hust Chat', active: true },
                  {name: 'Hust Menu', active: false },
                  {name: 'Hust Fiscal', active: false }
                ].map(prop => {
                  return (
                    <div>
                      Conta {prop.name}:
                      <span
                        style={{
                          padding: '0px 15px',
                          margin: 1,
                          backgroundColor: (prop.active ? successColor : grayColor)[0],
                          borderRadius: 5,
                          textAlign: "center",
                          color: '#FFF',
                          fontWeight: 700,
                          boxShadow: `${(prop.active ? successColor : grayColor)[3]} 0px 0px 10px -5px`
                        }}
                      >
                        Ativa
                      </span>
                    </div>
                  )
                })}
              </GridItem>*/}
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <Button
                  round
                  color={'hust'}
                  onClick={async () => {
                    // const company = { vat: company., name, address };
                    const { ok, id } = await WebService(`/admin/createCompany`, { ...company, id: companyId }, 'POST');
                    if(ok) {
                      await Swal.fire({ icon: "success", title: 'Cliente cadastrado com sucesso!' });
                      window.location.href = `/admin/client/${id}`;
                    }
                  }}
                >
                  {companyId ? 'Salvar' : 'Cadastrar'} Cliente
                </Button>
                {companyId ? (
                <Button
                  round
                  color={'hust'}
                  simple
                  // disabled={process.env.NODE_ENV !== "development"}
                  onClick={async () => {
                    const { value } = await Swal.fire({
                      icon: "question",
                      title: 'Deseja executar a rotina para este cliente?',
                      showCancelButton: true,
                      cancelButtonText: 'Não',
                      confirmButtonText: 'Sim'
                    });
                    if (!value) return;

                    await axios.put(`https://api.hustapp.com/admin/company/${companyId}/run`);
                  }}
                >
                  Executar rotina
                </Button>
                ) : ''}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      {/*********************************************************/}
      {companyId && (
        <>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <GridContainer>
              <GridItem lg={2} md={2} sm={4} xs={4}>
                <Button
                  round
                  color={activeTab === 0 ? 'hust' : 'gray'}
                  onClick={() => setActiveTab(0)}
                  fullWidth
                >
                  Assinaturas
                </Button>
              </GridItem>
              {/*<GridItem lg={2} md={2} sm={4} xs={4}>
                  <Button
                    fullWidth
                    round
                    color={activeTab === 1 ? 'hust' : 'gray'}
                    onClick={() => setActiveTab(1)}
                    disabled
                  >
                    Contratos
                  </Button>
              </GridItem>*/}
              <GridItem lg={2} md={2} sm={4} xs={4}>
                <Button
                  fullWidth
                  round
                  color={activeTab === 2 ? 'hust' : 'gray'}
                  onClick={() => setActiveTab(2)}
                >
                  Usuários
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
          {/*********************************************************/}
          {activeTab === 0 && (
            <GridItem lg={activeSubscription ? 7 : 12} md={activeSubscription ? 7 : 12} sm={12} xs={12}>
              <Card>
                <CardBody>
                  <Button
                    color={'hust'}
                    round
                    simple
                    onClick={async () => {
                      if (subscriptions.length) {
                        return Swal.fire({
                          icon: "warning",
                          title: 'O cliente já possui uma assinatura. Não é possível criar outra.'
                        });
                      }
                      const { value } = await Swal.fire({
                        icon: "question",
                        title: 'Deseja confirmar a criação de uma nova assinatura para o cliente?',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                      });

                      if (!value) return;

                      const {value: due_day} = await Swal.fire({icon: "question", title: "Digite o dia de vencimento do cliente.", input: "number"});
                      if (!due_day || due_day <= 0 || due_day >= 30) {
                        return await Swal.fire({ icon: "warning", title: 'O dia de vencimento informado é inválido.', });
                      }
                      const { ok } = await WebService('/admin/createSubscription', { id_company: companyId, due_day }, 'POST');
                      if (ok) {
                        await Swal.fire({ icon: 'success', title: 'Assinatura criada com sucesso!' });
                        getCompanySubscriptions(companyId);
                      }
                    }}
                  >
                    Nova assinatura
                  </Button>
                  <Table
                    hover
                    tableHead={[
                      '#',
                      'Período',
                      'Inicio',
                      'Final',
                      'Dia Venc.',
                      'Status',
                      'Tipo de Fat.',
                      'Opções'
                    ]}
                    tableData={subscriptions.map(prop => {
                      const { id, period_type, date_start, date_end, due_day, status, type } = prop;
                      return [
                        id,
                        periodType[period_type],
                        convertDate(date_start),
                        convertDate(date_end),
                        due_day,
                        statusType[status],
                        invoiceType[type],
                        (
                          <div>
                            {status === 'active' ? (
                              <Tooltip title={'Cancelar'}>
                                <Button
                                  round simple justIcon color={'danger'}
                                  style={{ minWidth: 0, width: 25, height: 25 }}
                                  onClick={() => cancelSubscription(id)}
                                >
                                  <Block/>
                                </Button>
                              </Tooltip>
                            ) : ''}
                            <Tooltip title={'Editar'}>
                              <Button
                                round simple justIcon color={'primary'}
                                style={{ minWidth: 0, width: 25, height: 25 }}
                                onClick={() => setActiveSubscription(activeSubscription ? null : id)}
                              >
                                <Settings/>
                              </Button>
                            </Tooltip>
                          </div>
                        )
                      ];
                    })}
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
          {activeTab === 1 && (
            <div>

            </div>
          )}
          {activeTab === 2 && (
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <Card>
                <CardBody>
                  <Button
                    color={'primary'}
                    round
                    simple
                    onClick={async () => {
                      const { value: name } = await Swal.fire({ icon: "question", title: 'Qual o nome do usuário?', input: 'text' });
                      if (!name) return Swal.fire({ icon: "warning", title: 'Nome inválido' });

                      const { value: email } = await Swal.fire({ icon: "question", title: 'Qual o email do usuário?', input: 'text' });
                      const { value: phone } = await Swal.fire({ icon: "question", title: 'Qual o Telefone do usuário?', input: 'text' });

                      if (!email && !phone) return Swal.fire({ icon: "warning", title: 'É necessário ter pelo menos uma informação de contato (Email/Telefone)' });

                      const { ok } = await WebService('/admin/createUser', { name, email, phone, id_company: companyId }, 'POST');
                      if (ok) {
                        await Swal.fire({icon: "success", title: 'Usuário cadastrado com sucesso!'});
                        getCompany(companyId);
                      }
                    }}
                  >
                    Novo usuário
                  </Button>
                  <Table
                    hover
                    striped
                    tableHead={[ '#', 'Nome', 'Email', 'Telefone', 'Opções' ]}
                    tableData={users.map(prop => [
                      prop.id,
                      prop.name,
                      prop.email,
                      prop.phone,
                      (
                        <Tooltip title={'Excluir usuário'}>
                          <Button
                            color={'danger'}
                            simple
                            justIcon
                            style={{ minWidth: 0, width: 15, height: 15, margin: '0px 1px' }}
                            round
                            onClick={async () => {
                              const { value } = await Swal.fire({
                                icon: "question",
                                title: 'Deseja realmente excluir o usuário?',
                                showCancelButton: true,
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não'
                              });

                              if (!value) return;

                              const { ok } = await WebService(`/admin/deleteUser`, { id: prop.id }, 'POST');
                              if (ok) {
                                await Swal.fire({ icon: "success", title: 'Usuário excluido com sucesso!' });
                                getCompany(companyId);
                              }
                            }}
                          >
                            <Close/>
                          </Button>
                        </Tooltip>
                      )
                    ])}
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
          {activeSubscription && (
            <GridItem lg={5} md={5} sm={12} xs={12}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={6} md={6} sm={6} xs={6}>
                      <Button
                        round
                        color={subscriptionTab === 'subscription' ? 'hust' : 'gray'}
                        onClick={() => setSubscriptionTab('subscription')}
                        fullWidth
                      >
                        Assinatura
                      </Button>
                    </GridItem>
                    <GridItem lg={6} md={6} sm={6} xs={6}>
                      <Button
                        fullWidth
                        round
                        color={subscriptionTab === 'invoice' ? 'hust' : 'gray'}
                        onClick={() => setSubscriptionTab('invoice')}
                      >
                        Faturas
                      </Button>
                    </GridItem>
                  </GridContainer>
                  {subscriptionTab === 'subscription' ? (
                    <div>
                      <h5 style={{ marginTop: 20, marginBottom: 0 }}>
                        Valor total da assinatura: &nbsp;
                        <strong style={{ color: successColor[0] }}>
                          {formatoDinheiro(
                            (
                              subscriptions[subscriptions.map(prop => prop.id).indexOf(activeSubscription)].products.reduce((total, product) => total + product.modules.reduce((total1, module) => total1 + (module.quantity * module.price), 0), 0)
                              * ((subscriptions[subscriptions.map(prop => prop.id).indexOf(activeSubscription)].discounts.filter(el => el.type === 'percentage').reduce((total, discount) => total + Number(discount.value), 0) / 100) || 1)
                            )
                            - subscriptions[subscriptions.map(prop => prop.id).indexOf(activeSubscription)].discounts.filter(el => el.type === 'fixed').reduce((total, discount) => total + Number(discount.value) * Number(discount.quantity), 0)
                          )}
                        </strong>
                      </h5>
                      <hr/>
                      <Button
                        color={'hust'}
                        style={{ height: 30 }}
                        simple
                        onClick={() => setModalAddProduct({idSubscription: activeSubscription})}
                      >
                        <AddCircleOutline/> Adicionar produto
                      </Button>
                      {subscriptions[subscriptions.map(prop => prop.id).indexOf(activeSubscription)].products.map(prop => {
                        return (
                          <GridContainer>
                            <GridItem lg={12} md={12} sm={12} xs={12}>
                              <h3>{prop.name}</h3>
                              <Table
                                hover
                                tableData={[
                                  ...prop.modules.map(module => [
                                    module.name,
                                    module.quantity,
                                    formatoDinheiro(module.price),
                                    (
                                      <strong>{formatoDinheiro(module.quantity * module.price)}</strong>
                                    ),
                                    <Tooltip title={'Excluir produto'}>
                                      <Button
                                        color={'danger'}
                                        simple
                                        justIcon
                                        style={{ minWidth: 0, width: 15, height: 15, margin: '0px 1px' }}
                                        round
                                        onClick={async () => {
                                          const { value } = await Swal.fire({
                                            icon: "question",
                                            title: 'Deseja realmente excluir este item para o cliente?',
                                            showCancelButton: true,
                                            cancelButtonText: 'Não',
                                            confirmButtonText: 'Sim'
                                          });
                                          if(!value) return;

                                          const { ok } = await WebService(`/admin/deleteProductSubscription`, { id: module.id_subscription_item });
                                          if (ok) {
                                            await Swal.fire({ icon: "success", title: 'Item excluído com sucesso!' });
                                            await getCompanySubscriptions();
                                          }
                                        }}
                                      >
                                        <Close/>
                                      </Button>
                                    </Tooltip>
                                  ]),
                                  [
                                    '',
                                    '',
                                    '',
                                    <Info>
                                      <strong>
                                        {formatoDinheiro(prop.modules.reduce((d, all) => d + (all.quantity * all.price), 0))}
                                      </strong>
                                    </Info>,
                                    ''
                                  ]
                                ]}
                              />
                            </GridItem>
                          </GridContainer>
                        );
                      })}
                      <h3>Descontos</h3>
                      <Button
                        color={'hust'}
                        style={{ height: 30 }}
                        simple
                        onClick={async () => {
                          const hasDiscount = !!subscriptions[subscriptions.map(prop => prop.id).indexOf(activeSubscription)].discounts.length;
                          if (hasDiscount) {
                            return Swal.fire({
                              icon: "warning",
                              title: 'Cada cliente só pode ter 1 desconto.',
                              text: 'Caso seja necessário alterar o desconto, remova o existe para adicionar um novo'
                            });
                          }

                          const { value: discount_type } = await Swal.fire({
                            title: "Selecione o tipo do desconto",
                            input: "select",
                            inputOptions: {
                              fixed: "Desconto de valor fixo",
                              percentage: "Desconto percentual"
                            },
                            inputPlaceholder: "Tipo de desconto",
                            showCancelButton: true,
                            cancelButtonText: 'Cancelar',
                            confirmButtonText: 'Próximo'
                          });
                          if (![ 'fixed', 'percentage' ].includes(discount_type))
                            return Swal.fire({ icon: "warning", title: 'Tipo de desconto inválido' });

                          let quantity = 1
                          if (discount_type === 'fixed') {
                            const { value } = await Swal.fire({
                              icon: "question",
                              title: "Digite a quantidade mutiplicadora do desconto.",
                              input: "number"
                            });
                            quantity = Number(value);
                          }
                          if (!quantity || quantity <= 0)
                            return Swal.fire({ icon: "warning", title: 'Quantidade de desconto inválido' });

                          const { value } = await Swal.fire({
                            icon: "question",
                            title: "Digite o valor do desconto.",
                            input: "number",
                            inputAttributes: { step: '0.01' },
                          });
                          if (!value || value <= 0)
                            return Swal.fire({ icon: "warning", title: 'Valor do desconto inválido' });

                          const { value: description } = await Swal.fire({
                            icon: "question",
                            title: "Digite a descrição do desconto.",
                            input: "text"
                          });
                          if (!description)
                            return Swal.fire({ icon: "warning", title: 'Descrição do desconto inválido' });

                          const discount = {
                            id_subscription: activeSubscription,
                            type: discount_type,
                            quantity,
                            value,
                            description
                          };
                          const { ok } = await WebService(`/admin/createDiscount`, discount, 'POST');
                          if (ok) {
                            await Swal.fire({ icon: "success", title: 'Desconto cadastrado com sucesso!' });
                            getCompanySubscriptions(companyId);
                          }
                        }}
                      >
                        <AddCircleOutline/> Adicionar desconto
                      </Button>
                      <Table
                        hover
                        tableData={subscriptions[subscriptions.map(prop => prop.id).indexOf(activeSubscription)].discounts.map(prop => [
                          prop.description,
                          discountType[prop.type].name,
                          prop.type !== 'percentage' ? `${prop.quantity}x` : '',
                          <Info><strong>{discountType[prop.type].function(prop.value)}</strong></Info>,
                          <Tooltip title={'Excluir desconto'}>
                            <Button
                              color={'danger'}
                              simple
                              justIcon
                              style={{ minWidth: 0, width: 15, height: 15, margin: '0px 1px' }}
                              onClick={async () => {
                                const { value } = await Swal.fire({
                                  icon: "question",
                                  title: 'Deseja realmente excluir este desconto para o cliente?',
                                  showCancelButton: true,
                                  cancelButtonText: 'Não',
                                  confirmButtonText: 'Sim'
                                });
                                if(!value) return;

                                const { ok } = await WebService(`/admin/deleteDiscount`, { id: prop.id });
                                if (ok) {
                                  await Swal.fire({ icon: "success", title: 'Desconto excluído com sucesso!' });
                                  await getCompanySubscriptions();
                                }
                              }}
                              round
                            >
                              <Close/>
                            </Button>
                          </Tooltip>
                        ])}
                      />
                    </div>
                  ) : (
                    <Table
                      tableHead={[ '#', 'Comp.', 'Vencimento', 'Valor', 'Status', 'Opções' ]}
                      hover
                      striped
                      tableData={subscriptions[subscriptions.map(prop => prop.id).indexOf(activeSubscription)].invoices.sort((a, b) => {
                        if (new Date(a.date_due).getTime() < new Date(b.date_due).getTime()) return 1;
                        if (new Date(a.date_due).getTime() > new Date(b.date_due).getTime()) return -1;
                        return 0
                      }).map(prop => {
                        const { id, competence, date, date_due, value, status, file_link } = prop;
                        const statusLabel = {
                          paid: <Success><strong>Pago</strong></Success>,
                          pending: new Date().getTime() >= new Date(date_due).getTime() ?
                            <Warning><strong>Atrasada</strong></Warning> : <Info><strong>Pendente</strong></Info>,
                          canceled: <Danger><strong>Cancelada</strong></Danger>
                        };
                        return [
                          id,
                          convertDate(competence).split('/').slice(1).join('/'),
                          convertDate(date_due),
                          formatoDinheiro(value),
                          statusLabel[status],
                          (
                            status !== 'canceled' ? (
                              <div>
                                {status === 'pending' ? (
                                  <Tooltip title={'Cancelar'}>
                                    <Button
                                      round simple justIcon color={'danger'}
                                      style={{ minWidth: 0, width: 25, height: 25 }}
                                      onClick={() => cancelInvoice(id)}
                                    >
                                      <Block/>
                                    </Button>
                                  </Tooltip>
                                ) : ''}
                                <Tooltip title={'Visualizar'}>
                                  <Button
                                    round simple justIcon color={'success'}
                                    style={{ minWidth: 0, width: 25, height: 25 }}
                                    onClick={() => window.open(file_link)}
                                  >
                                    <FindInPage/>
                                  </Button>
                                </Tooltip>
                                <Tooltip title={'Enviar para cliente'}>
                                  <Button
                                    round simple justIcon color={'info'}
                                    style={{ minWidth: 0, width: 25, height: 25 }}
                                    onClick={() => sendInvoice(prop)}
                                  >
                                    <SendIcon/>
                                  </Button>
                                </Tooltip>
                              </div>
                            ) : ''
                          )
                        ];
                      })}
                    />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          )}
        </>
      )}
    </GridContainer>
  );
};

export default ClientConfiguration;