import Swal, { showLoading } from "sweetalert2";
import axios from "axios";
import { Route } from "react-router-dom";
import React from "react";

const localConfigHost = JSON.parse(localStorage.getItem("localConfigHost"));

export const configHost =
  process.env.NODE_ENV === "production"
    ? {
        protocol: "s",
        baseURL: window.location.host.includes('teste.hust.chat') ? 'apiv1_teste.hustapp.com' : 'apiv1.hustapp.com',
        portHttp: "",
        portWSS: 2083,
        portWS: 19549,
        versionAPI: `/v1`,
        ...localConfigHost,
      }
    : {
        protocol: "",
        baseURL: "127.0.0.1",
        portHttp: ":8282",
        portWSS: 19656,
        portWS: 19656,
        versionAPI: `/v1`,
        ...localConfigHost,
      };

// const host_api = localStorage.getItem(`host_api`);
// if (host_api) configHost.baseURL = host_api;

export function convertDate(
  inputFormat,
  sqlformat = false,
  datetime = false,
  onlyTime = false
) {
  if (inputFormat === "" || !inputFormat) return "";

  function pad(s) {
    return s < 10 ? "0" + s : s;
  }

  var d = new Date(inputFormat);
  var time = "";
  if (datetime || onlyTime)
    time = " " + [pad(d.getHours()), pad(d.getMinutes())].join(":");
  if (onlyTime) return time;
  if (sqlformat)
    return (
      [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-") +
      time
    );
  else
    return (
      [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/") +
      time
    );
}

const undefinedfunction = () => {};

export const apiURL = `http${configHost.protocol}://${configHost.baseURL}${configHost.portHttp}${configHost.versionAPI}`;
export const apiURLWithoutVersion = `http${configHost.protocol}://${configHost.baseURL}${configHost.portHttp}`;

export const WebService = async (
  path,
  parameters = {},
  method = "GET",
  loading = true,
  onUploadProgress = undefinedfunction,
  showErro = true
) => {
  const timeout = setTimeout(() => {
    if (loading) {
      Swal.fire({ title: "Carregando", allowOutsideClick: false });
      Swal.showLoading();
    }
  }, 800);

  try {
    const { token, PARTNER_TOKEN } = localStorage;
    const request = {};
    let parametersUrl = ``;

    parameters = { ...parameters, token: token || PARTNER_TOKEN };

    if (method === "GET") {
      Object.keys(parameters)
        .filter((el) => parameters[el] !== undefined || parameters[el] !== null)
        .map(
          (prop) =>
            (parametersUrl += `${parametersUrl ? "&" : "?"}${prop}=${
              parameters[prop]
            }`)
        );
    } else if (method === "POST") {
      request.body = JSON.stringify(parameters);
    }

    const requestReturn = await fetch(`${apiURL}${path}${parametersUrl}`, {
      method,
      ...request,
    });
    const data = await requestReturn.json();
    /*const {data} = await axios({
        method,
        data: request.body,
        url: `http${configHost.protocol}://${configHost.baseURL}${configHost.portHttp}${configHost.versionAPI}${path}${parametersUrl}`,
        onUploadProgress
    });*/
    clearTimeout(timeout);
    if (loading) Swal.close();
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    const {
      ok,
      mensagem_usuario,
      descricao_usuario,
      codigo_erro,
      tipo_mensagem,
      erros_aplicacao,
    } = data;
    if (!ok && showErro) {
      if (codigo_erro === 46135789165) {
        await Swal.fire({
          title: `Usuário desconectado.`,
          text: `Por favor, faça login e tente novamente.`,
          icon: "warning",
        });
        const localConfigHost = localStorage.getItem("localConfigHost");
        localStorage.clear();
        localStorage.setItem("localConfigHost", localConfigHost);
        window.location.href = "/";
        return { ok: false };
      } else {
        await Swal.fire({
          title: mensagem_usuario,
          icon: tipo_mensagem || "warning",
          text: descricao_usuario,
          html:
            erros_aplicacao &&
            erros_aplicacao
              .map((prop) => prop["mensagem_usuario_html"])
              .join("<br/>"),
        });
      }
    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    return data;
  } catch (e) {
    await clearTimeout(timeout);
    if (showErro) {
      await Swal.fire({
        icon: "warning",
        title: "Não foi possivel processar a operação solicitada.",
        text: e.toString(),
      });
    }
    return { ok: false };
  }
};

export const LoadRouterProps = ({ component: Component, ...props }) => (
  <Route render={(p) => <Component {...props} {...p} />} />
);
