import React from 'react';
import {TaskPriorityLabel} from "../taskHelper";
import {updateTask} from "../taskHelper";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";

const TaskLabelStatus = ({id, priority, showFullName, simple = true, onChange}) => {
  return (
    <CustomDropdown
      buttonText={(showFullName ? 'Prioridade ' : '') + TaskPriorityLabel[priority]?.label}
      buttonProps={{
        simple,
        round: true,
        style: {marginBottom: "0", height: 24, width: showFullName ? 140 : 90},
        color: TaskPriorityLabel[priority]?.color
      }}
      dropdownList={Object.keys(TaskPriorityLabel).map(prop => ({
        value: prop,
        label: (showFullName ? 'Prioridade ' : '') + TaskPriorityLabel[prop].label
      }))}
      noLiPadding={true}
      onClick={priority => updateTask(id, {priority}).then(() => onChange({priority}))}
    />
  );
}

export default TaskLabelStatus;