import React, { useEffect, useState } from "react";
import { MenuItem, Avatar, Divider, Typography } from "@mui/material";
import Popover from "@material-ui/core/Popover";
import Swal from "sweetalert2";
import api from "../../../components/api";

export default function PipelineSelector({ isOpen, onClose, taskId, pipelineId }) {
  const [pipelines, setPipelines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Busca pipelines uma vez ao inicializar o componente
  useEffect(() => {
      api.get("/task/pipeline")
          .then(({ data }) => setPipelines(data))
          .catch((err) => console.error("Erro ao carregar pipelines:", err));
    },[]);

  // Transferência de task
  const changeTaskPipeline = async (targetPipelineId) => {
    if (isLoading || targetPipelineId === pipelineId) return;

    setIsLoading(true);

    try {
      await api.put(`/task/${taskId}`, { pipeline: { id: targetPipelineId } });

      Swal.fire({
        icon: "success",
        title: "Tarefa transferida com sucesso!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });

      onClose();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro ao transferir a tarefa",
        text: "Não foi possível mover a tarefa. Tente novamente.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <Popover
          open={isOpen}
          onClose={onClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2 }}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          transformOrigin={{ vertical: "center", horizontal: "center" }}
          PaperProps={{
            style: {
              borderRadius: 12,
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
              padding: 20,
              width: 320,
              backgroundColor: "#fff",
            },
          }}
      >
        {pipelines.map((pipeline) => (
            <div key={pipeline.id}>
              <MenuItem
                  disabled={pipeline.id === pipelineId || isLoading} // Desabilita botão se estiver carregando
                  onClick={() => changeTaskPipeline(pipeline.id)}
                  style={{
                    backgroundColor: pipeline.id === pipelineId ? "#f1f1f1" : "transparent",
                    padding: "12px 16px",
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8,
                    cursor: pipeline.id === pipelineId || isLoading ? "not-allowed" : "pointer",
                    transition: "all 0.3s ease",
                  }}
              >
                <Avatar style={{ marginRight: 15, backgroundColor: "#ff4d4f", color: "#fff" }}>
                  {pipeline.name[0].toUpperCase()}
                </Avatar>
                <Typography
                    variant="body1"
                    style={{
                      flex: 1,
                      fontWeight: "500",
                      color: "#333",
                      fontSize: "16px",
                    }}
                >
                  {pipeline.name}
                </Typography>
              </MenuItem>
              <Divider light style={{ margin: "8px 0" }} />
            </div>
        ))}
      </Popover>
  );
}
