import React, {useState, useRef, useCallback, useEffect} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MarkerType,
  MiniMap,
  updateEdge,
  ControlButton,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Sidebar from './Sidebar';
import './index.css';
import {GridContainer, GridItem} from "../../components";
import CustomNodes from './CustomNodes';
import {v4} from 'uuid';
import Swal from "sweetalert2";
import api from "../../components/api";
import {apiURL, apiURLWithoutVersion, WebService} from "../../components/WebService";

const initialNodes = [];
const initialEdges = [];
let yCont = 0;

const BotNovo = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  // -------------------------------------------------------------------------------------------------------------------

  const mountFlow = (flow, nodeFather) => {
    const nodes = [];
    const edges = [];

    if (!flow) return [];
    const {type, nextStep} = flow;

    const node = {id: v4(), data: {}, position: {x: yCont + 1050, y: 200}};
    yCont += 200;
    if (type === 'message') {
      const {message} = flow;
      node.type = 'sendMessage';
      node.data.text = message;
    } else if (type === 'switchOption') {
      const {options, messageInvalidOption, messageSwitchOptions, responseType, button, department} = flow;
      const optionsKey = Object.keys(options);

      node.type = 'switchOption';
      node.data = {
        messageSwitchOptions,
        messageInvalidOption,
        responseType,
        button,
        department,
        options: optionsKey.map(prop => ({key: prop, value: options[prop].message})),
      };

      for (let i = 0; i < optionsKey.length; i += 1) {
        const key = optionsKey[i];
        const {message, nextStep} = options[key];
        const {nodes: childNodes, edges: childEdges} = mountFlow(nextStep, node);
        nodes.push(...childNodes);
        edges.push(...childEdges.map(prop => {
          // console.log(`${node.id}_handle_${i}`);
          if (prop.source === node.id) prop.sourceHandle = `${node.id}_handle_${i}`;
          return prop;
        }));
      }
    } else if (type === 'userResponse') {
      const {message, variable, responseType} = flow;
      node.type = 'userResponse';
      node.data = {message, variable, responseType};
    } else if (type === 'callAPI') {
      const {api, jsonNode, method, body} = flow;
      node.type = 'callAPI';
      node.data = {api, jsonNode, method, body};
    } else if (type === 'timeout') {
      const {time} = flow;
      node.type = 'timeout';
      node.data.time = time;
    } else if (type === 'media') {
      const {mediaType, source, fileName, mimeType} = flow;
      node.type = 'media';
      node.data = {mediaType, source, fileName, mimeType};
    }

    if (nodeFather) {
      const {id: idFater} = nodeFather;
      const {id: idNode} = node;
      edges.push({
        id: `${idNode}_${idFater}`,
        source: idFater,
        target: idNode,
        label: '',
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      });
    }
    nodes.push(node);
    if (nextStep) {
      const {nodes: childNodes, edges: childEdges} = mountFlow(nextStep, node);
      nodes.push(...childNodes);
      edges.push(...childEdges);
    }
    // console.log({nodes});
    // makeNodes.push(...nodes);
    return {nodes, edges};
  };

  useEffect(() => {
    setNodes([]);
    setEdges([]);
    setTimeout(() => document.getElementsByClassName('react-flow__panel react-flow__attribution bottom right')[0].style.opacity = 0, 200);
  }, []);

  const [ bots, setBots ] = useState([]);

  const getBot = async () => {
    const { data } = await api.get('/bot', { params: { raw: true } });
    if (data[0]) {
      setBots(data.map(prop => {
        const bot = { ...prop };
        delete bot['dataRaw'];
        return bot;
      }));
      const {nodes, edges} = data[0].dataRaw;
      setNodes(nodes);
      setEdges(edges);
    }
  };

  useEffect(() => {
    getBot();
  }, []);

  // -------------------------------------------------------------------------------------------------------------------

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) return;

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {id: v4(), type, position, data: {}};

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const getHustBotFlow = async ({nodes, edges}, node = null) => {
    const connections = edges.filter(el => el.source === node.id);

    if (connections.length > 1 && node.type !== 'switchOption' && node.type !== 'conditional') {
      if (node.type === 'userResponse' && node.data.flagLimitResponseTime) {
// Ta safe
      } else {
        await Swal.fire({
          icon: "warning",
          title: 'Um nó não pode ter mais de uma conexão',
          text: 'Verífique todos os nós de interação e remova a segunda conexão.'
        });
        throw new Error('');
      }
    }

    // const token = localStorage.getItem('token');
    const token = '{{{INTERNAL_TOKEN}}}';

    const hustBotStep = {type: node.type};
    if (node.type === 'sendMessage') {
      hustBotStep.type = 'message';
      hustBotStep.message = node.data.text;
    } else if (node.type === 'switchOption') {
      hustBotStep.messageInvalidOption = node.data.messageInvalidOption;
      hustBotStep.messageSwitchOptions = node.data.messageSwitchOptions;
      hustBotStep.responseType = node.data.responseType;
      hustBotStep.button = node.data.button;
      hustBotStep.department = node.data.department;
      hustBotStep.options = {};

      // * * * REDUNDANTE * * *
      hustBotStep.limitResponseTime = node.data.flagLimitResponseTime;
      hustBotStep.time = null;
      hustBotStep.replyQuantity = null;
      if (node.data.flagLimitResponseTime) {
        const [minutes, seconds] = node.data.timeResponse.toString().split(':').map(Number);
        hustBotStep.time = ((minutes * 60) + seconds) * 1000;

        hustBotStep.replyQuantity = Number(node.data.repeatQuantity);
        const connectionOption = connections.find(el => el.sourceHandle.toString().includes('handle_escape_'));
        const nodeOption = nodes.find(el => el.id === connectionOption.target);
        hustBotStep.options = {escape: {message: '', nextStep: await getHustBotFlow({nodes, edges}, nodeOption)}};
      }
      // * * *

      for (let i = 0; i < node.data.options.length; i += 1) {
        const {key, value: message} = node.data.options[i];
        const connectionOption = connections.find(el => el.sourceHandle.split('_').slice(-1)[0].toString() === i.toString());
        const nodeOption = nodes.find(el => el.id === connectionOption.target);
        hustBotStep.options[key] = {message, nextStep: await getHustBotFlow({nodes, edges}, nodeOption)};
      }
    } else if (node.type === 'userResponse') {
      hustBotStep.message = node.data.message;
      hustBotStep.variable = node.data.variable;
      hustBotStep.messageInvalidOption = node.data.messageInvalidOption;
      hustBotStep.responseType = node.data.responseType;

      // * * * REDUNDANTE * * *
      hustBotStep.limitResponseTime = node.data.flagLimitResponseTime;
      hustBotStep.time = null;
      hustBotStep.replyQuantity = null;
      if (node.data.flagLimitResponseTime) {
        const [minutes, seconds] = node.data.timeResponse.toString().split(':').map(Number);
        hustBotStep.time = ((minutes * 60) + seconds) * 1000;

        hustBotStep.replyQuantity = Number(node.data.repeatQuantity);
        const connectionOption = connections.find(el => el.sourceHandle.toString().includes('handle_escape_'));
        const nodeOption = nodes.find(el => el.id === connectionOption.target);
        hustBotStep.options = {escape: {message: '', nextStep: await getHustBotFlow({nodes, edges}, nodeOption)}};
      }
      // * * *

      hustBotStep.nextStep = {
        type: 'callAPI',
        api: `${apiURLWithoutVersion}/v1/contato/adicionarDadoContato`,
        jsonNode: [],
        method: 'POST',
        body: {
          nome: node.data.variable,
          token,
          valor: `{{{${node.data.variable}}}}`,
          id_contato: "{{{contact.id}}}"
        }
      };
    } else if (node.type === 'callAPI') {
      hustBotStep.api = node.data.api;
      hustBotStep.jsonNode = node.data.jsonNode;
      hustBotStep.method = node.data.method;
      hustBotStep.body = node.data.body;
    } else if (node.type === 'transferCalled') { // Transferir atendimento
      hustBotStep.type = 'callAPI';
      hustBotStep.api = `${apiURLWithoutVersion}/v1/chamado/transferirAtendimento`;
      hustBotStep.jsonNode = [];
      hustBotStep.method = 'POST';
      hustBotStep.body = {
        id_departamento: parseFloat(node.data.department),
        id_usuario: '',
        id_chamado: '{{{called.id}}}',
        token,
      };
      hustBotStep.nextStep = {type: 'message', message: ''};
    } else if (node.type === 'createTAG') { // Adicionar TAG
      hustBotStep.type = 'callAPI';
      hustBotStep.api = `${apiURLWithoutVersion}/v1/chamado/criarTag?id_chamado={{{called.id}}}&nome=${encodeURI(node.data.text)}&token=${token}`;
      hustBotStep.jsonNode = [];
      hustBotStep.method = 'GET';
      hustBotStep.body = {};
      hustBotStep.nextStep = {type: 'message', message: ''};
    } else if (node.type === 'endCalled') { // Finalizar chamado
      hustBotStep.type = 'callAPI';
      hustBotStep.api = `${apiURLWithoutVersion}/v1/chamado/finalizarChamado?id_chamado={{{called.id}}}&token=${token}&flag_silencioso=true`;
      hustBotStep.jsonNode = [];
      hustBotStep.method = 'GET';
      hustBotStep.body = {};
      hustBotStep.nextStep = {type: 'message', message: ''};
    } else if (node.type === 'chooseDepartment') { // Escolha de departamentos
      const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
      if (!ok) throw new Error('');

      hustBotStep.type = 'switchOption';
      hustBotStep.messageInvalidOption = '😕 Desculpa, não consegui entender a opção escolhida.\n\n' +
        '🔢 Por favor, digite número da opção desejada:';
      hustBotStep.messageSwitchOptions = node.data.message;
      hustBotStep.responseType = 'number';
      hustBotStep.button = node.data.button;
      hustBotStep.department = false;
      const detDepartmentsOption = (departments, father) => {
        const options = {};
        for (let i = 0; i < departments.length; i += 1) {
          const {nome, id_departamento, flag_departamento_interno, subDepartamentos} = departments[i];
          if (flag_departamento_interno) continue;
          if (subDepartamentos) options[i + 1] = {
            message: nome,
            nextStep: {
              type: 'switchOption',
              messageInvalidOption: '😕 Desculpa, não consegui entender a opção escolhida.\n\n' +
                '🔢 Por favor, digite número da opção desejada:',
              messageSwitchOptions: `Escolha o sub-departamento para ${nome}`,
              responseType: 'number',
              button: node.data.button,
              department: false,
              options: detDepartmentsOption(subDepartamentos, departments[i])
            }
          };
          else options[i + 1] = {
            message: nome,
            nextStep: {
              type: 'callAPI',
              api: `${apiURLWithoutVersion}/v1/chamado/transferirAtendimento`,
              jsonNode: [],
              method: 'POST',
              body: {
                id_departamento: parseFloat(id_departamento),
                id_usuario: '',
                id_chamado: '{{{called.id}}}',
                token, // FIX - Corrigir urgentemente
              },
              nextStep: {type: 'message', message: ''}
            },
          };
        }

        return options;
      };

      hustBotStep.options = detDepartmentsOption(departamentos);
    } else if (node.type === 'conditional') {
      hustBotStep.type = 'conditional';
      hustBotStep.value1 = node.data.value1;
      hustBotStep.value2 = node.data.value2;
      hustBotStep.operator = node.data.operator;

      const connectionOptionTrue = connections.find(el => el.sourceHandle.toString().includes('_target_true'));
      const nodeOptionTrue = nodes.find(el => el.id === connectionOptionTrue.target);

      const connectionOptionFalse = connections.find(el => el.sourceHandle.toString().includes('_target_false'));
      const nodeOptionFalse = nodes.find(el => el.id === connectionOptionFalse.target);

      hustBotStep.options = {
        response_true: {message: '', nextStep: await getHustBotFlow({nodes, edges}, nodeOptionTrue)},
        response_false: {message: '', nextStep: await getHustBotFlow({nodes, edges}, nodeOptionFalse)}
      };
    } else if (node.type === 'sendConnectionEvent') { // Envia evento
      hustBotStep.type = 'callAPI';
      hustBotStep.api = `${apiURLWithoutVersion}/v1/whatsapp_api/setPresence?uuid={{{connection.uuid}}}&presence=${node.data.event}&id={{{contact.id}}}&token=${token}`;
      hustBotStep.jsonNode = [];
      hustBotStep.method = 'GET';
      hustBotStep.body = {};
      hustBotStep.nextStep = {type: 'message', message: ''};
    } else if (node.type === 'timeout') {
      hustBotStep.time = node.data.time;
    } else if (node.type === 'media') {
      hustBotStep.source = node.data.source;
      hustBotStep.fileName = node.data.fileName;
      // hustBotStep.mimeType = node.data.mimeType;
      hustBotStep.mediaType = node.data.mediaType;
      switch (node.data.mediaType) {
        case 'image':
          hustBotStep.mimeType = 'image/png';
          hustBotStep.sticker = node.data.sticker;
          if (!hustBotStep.fileName.includes('.png')) hustBotStep.fileName += `.png`;
          break;
        case 'audio':
          hustBotStep.mimeType = 'audio/mp3';
          if (!hustBotStep.fileName.includes('.mp3')) hustBotStep.fileName += `.mp3`;
          break;
        case 'video':
          hustBotStep.mimeType = 'video/mp4';
          if (!hustBotStep.fileName.includes('.mp4')) hustBotStep.fileName += `.mp4`;
          break;
        case 'pdf':
          hustBotStep.mimeType = 'application/pdf';
          if (!hustBotStep.fileName.includes('.pdf')) hustBotStep.fileName += `.pdf`;
          break;
      }
      hustBotStep.fileName = hustBotStep.fileName.split('.').slice(-1).join('.') + '.' + hustBotStep.mimeType.split('/')[1]
    }

    if (connections.length) {
      if (node.type !== 'switchOption' && node.type !== 'conditional') {
        const [connection] = connections.filter(el => !el.sourceHandle || (!el.sourceHandle.toString().includes('handle_escape_') && !el.sourceHandle.toString().includes('_target_true') && !el.sourceHandle.toString().includes('_target_false')));
        const nodeTarget = nodes.find(el => el.id === connection.target);
        const nextFlow = await getHustBotFlow({nodes, edges}, nodeTarget);
        if (hustBotStep.nextStep) hustBotStep.nextStep.nextStep = nextFlow;
        else hustBotStep.nextStep = nextFlow;
      }
    }

    return hustBotStep;
  };

  const processAllBotsFlow = async ({ nodes, edges }) => {
    try {
      const startsNode = nodes.filter(el => el.type === 'start');
      if (!startsNode.length) throw new Error('É necessário pelo menos um nó inicial indicando o início do BOT');

      const allBotsHust = [];

      for (const startNode of startsNode) {
        let {enabled, callerType, department, word, id, name} = startNode.data;
        if (!name) throw new Error(`É necessário definir o nome do BOT. [${id}]`);

        // Validações baseadas no tipo de acionamento
        if (callerType === 'Word') {
          if (!word) throw new Error('É necessário definir a palavra de acionamento do BOT');
          department = undefined;
        }
        if (callerType === 'WaitInDepartment') {
          if (!department) throw new Error('É necessário definir o departamento para acionamento do BOT');
          word = undefined;
          department = parseFloat(department);
        }
        if (callerType === 'Campaign') {
          enabled = true;
          word = undefined;
          department = undefined;
        }

        // Processa o fluxo do BOT
        const {nextStep: flow} = await getHustBotFlow({nodes, edges}, startNode);

        allBotsHust.push({
          id,
          internalId: startNode.id,
          config: {enabled, callerType, department, word},
          flow,
          name,
        });
      }

      return allBotsHust;
    } catch (e) {
      Swal.fire({icon: "warning", title: e.toString().split(`Error: `)[1]});
    }
  };

  const saveAllBots = async () => {
      const dataRaw = { nodes, edges };
      const allBotsHust = await processAllBotsFlow(dataRaw);

      // Inclua o dataRaw para cada bot antes de enviar
      const botsWithRawData = allBotsHust.map(bot => ({
        ...bot,
        dataRaw, // Adiciona o dataRaw no formato necessário
      }));

      Swal.fire({
        title: `Salvando Bots`,
        text: `Processando todos os bots...`,
        allowOutsideClick: false
      });
      Swal.showLoading();

      // Envia os bots em um único lote para o backend
      const { data } = await api.put('/bot/batch', { bots: botsWithRawData });

      // Atualizar os nodes e edges com os dados retornados do backend
      if (data.bots) {
        const [{nodes, edges}] = data.bots;
        setNodes(nodes); // Configure os nodes
        setEdges(edges); // Configure as edges
      }
      Swal.fire({icon: "success", title: 'Bots salvos com sucesso!'});
    };

  // ******************************
  const edgeUpdateSuccessful = useRef(true);

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);
  // ******************************
  return (
    <GridContainer>
      <GridItem
        lg={12} md={12} sm={12} xs={12}
        style={{height: '100vh', border: '2px solid #000'}}
      >
        <div className="dndflow">
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges.map(prop => {
                  prop.animated = true;
                  prop.markerEnd = {type: MarkerType.ArrowClosed};
                  prop.style = {strokeWidth: 2};
                  return prop;
                })}
                nodeTypes={CustomNodes}
                onNodesChange={onNodesChange}
                onNodesDelete={id => setNodes(nodes => [...nodes.filter(el => el.id !== id)])}
                onEdgesChange={onEdgesChange}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                fitView
              >
                <MiniMap/>
                <Controls/>
                <Background/>
              </ReactFlow>
            </div>
            <Sidebar onSave={() => saveAllBots()} bots={bots} nodes={nodes}/>
          </ReactFlowProvider>
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default BotNovo;
