import React, {Fragment, useEffect, useRef, useState} from 'react';
import Board, {addColumn} from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import {Card, CardBody, CardHeader, GridContainer, GridItem} from "../../components";
import TaskLabelStatus from "./components/TaskLabelStatus";
import ViewUserActions from "./components/ViewUserActions";
import {Avatar, Box, FormControl, List, ListItemText, makeStyles, Select, Tab, Tabs, Tooltip} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {ListItemButton} from "@mui/material";
import {Add, ListAlt, Person, PersonAdd} from "@material-ui/icons";
import {MdOutlineDashboard, MdOutlineModeEdit} from "react-icons/md";
import {BsKanban} from "react-icons/bs";
import {CiBoxList} from "react-icons/ci";
import {FaChartGantt} from "react-icons/fa6";
import { MdDeleteOutline } from "react-icons/md";
import api from "../../components/api";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from '@mui/material/Menu';
import {IoMdArrowDropdown, IoMdArrowDropright} from "react-icons/io";
import {
  hexToRgb,
  infoColor,
  primaryColor,
  successColor,
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import {FaPlus, FaRegEdit} from "react-icons/fa";
import Button from "../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import TaskManager from "./TaskManager";
import {Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption} from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import ModalCalledHistory from "../Modal/CalledHistory";
import TaskViewer from "../Modal/Task/TaskViewer";
import {Doughnut} from "react-chartjs-2";
import {removeTask, TaskStatusLabel} from "./taskHelper";
import AddToHomeScreen from "@material-ui/icons/AddToHomeScreen";
import SendIcon from "@material-ui/icons/Send";
import {formatoDinheiro, pontuacaoNumero} from "../../components/Funcoes";
import CardIcon from "../../components/Card/CardIcon";
import CardFooter from "../../components/Card/CardFooter";
import {checkBeta} from "../BetaControll";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Swal from "sweetalert2";
import InputLabel from "@material-ui/core/InputLabel";
import {IoClose} from "react-icons/io5";
import PipelineSelector from "../Modal/Task/TransferTask";
const useStyles = makeStyles(dashboardStyle);

const Pipeline = () => {
  const classes = useStyles();

  const [board, setBoard] = useState({columns: []});
  const [pipelines, setPipelines] = useState([]);
  const [currentPipeline, setCurrentPipeline] = useState({id: null, name: null, type: '', stage: []});
  const [openListPipelines, setOpenListPipelines] = useState(false);
  const [modalManagerPipeline, setModalManagerPipeline] = useState({open: false, id: null, name: '', picture: '', type: ''});

  const [openTaskID, setOpenTaskID] = useState(null);
  const [openChatId, setOpenChatId] = useState(null);

  const [tab, setTab] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false); // Para controlar a abertura do modal
  const [newStageName, setNewStageName] = useState(''); // Nome da nova coluna
  const [newStageColor, setNewStageColor] = useState('#FF0000'); // Cor padrão da nova coluna

  const refMenuPipelines = useRef(null);

  const getPipelines = async (current = null) => {
    const {data} = await api.get('/task/pipeline');
    setPipelines(data);
    const [{id, name}] = data;
    setCurrentPipeline({id, name, stage: []});
    // console.log({id});
    await getPipeline(current || id);
  };

  const createPipeline = async () => {
    const {name, type} = modalManagerPipeline;
    if (!name) return Swal.fire({icon: "warning", title: 'É necessário digitar o nome da pipeline.'});
    if (!['task', 'lead', 'internal'].includes(type)) return Swal.fire({icon: "warning", title: 'Tipo inválido.'});

    const {data} = await api.post(`/task/pipeline`, {name, type});
    setModalManagerPipeline({open: false, id: null, name: '', picture: '', type: ''});
    await Swal.fire({icon: "success", title: 'Pipeline criada com sucesso!'});
    getPipelines(data.id);
  };
  // Editar Pipeline
  const handleEditPipeline = async () => {
    const { id, name, type } = modalManagerPipeline;

    if (!name) {
      return Swal.fire({
        icon: "warning",
        title: "É necessário digitar o nome da pipeline.",
      });
    }
    if (!["task", "lead", "internal"].includes(type)) {
      return Swal.fire({
        icon: "warning",
        title: "Tipo inválido.",
      });
    }

    try {
      // Fazendo uma requisição PUT para atualizar a pipeline
      await api.put(`/task/pipelines/${id}`, {
        name,
        type,
      });

      // Exibe uma mensagem de sucesso
      Swal.fire({
        icon: "success",
        title: "Pipeline atualizada com sucesso!",
      });

      // Fecha o modal e recarrega as pipelines
      setModalManagerPipeline({ open: false, id: null, name: "", type: "" });
      await getPipelines(id); // Atualiza a lista e mantém a pipeline selecionada
    } catch (error) {
      console.error("Erro ao atualizar a pipeline:", error);

      // Exibe uma mensagem de erro
      Swal.fire({
        icon: "error",
        title: "Erro ao atualizar a pipeline.",
      });
    }
  };

  // Excluir pipeline
  const handleDeletePipeline = async (pipelineId) => {
    try {
      // Tentando excluir a pipeline
      const response = await api.delete(`/task/pipelines/${pipelineId}`);

      // Exibe um alerta de sucesso
      Swal.fire({
        icon: "success",
        title: "Pipeline excluída com sucesso!",
      });

      // Atualiza a lista de pipelines após a exclusão
      setPipelines((prevPipelines) =>
          prevPipelines.filter((pipeline) => pipeline.id !== pipelineId)
      );
    } catch (error) {
      // Se a exclusão falhar, exibe a mensagem de erro
      console.error("Erro ao excluir pipeline:", error.response?.data || error.message);

      Swal.fire({
        icon: "error",
        title: "Erro ao excluir pipeline",
        text: error.response?.data?.message || "Ocorreu um erro durante a exclusão da pipeline.",
      });
    }
  };

  const getPipeline = async (id = currentPipeline.id) => {
    const {data} = await api.get(`/task/pipeline/${id}`);
    setCurrentPipeline(data);
  };

  const changeTask = async (id, params, request = true, notify = true) => {
    if (request) await api.put(`/task/${id}`, params);
    setCurrentPipeline(pipeline => {
      pipeline.stage.forEach((stage, k1) => {
        stage.tasks.forEach((task, k2) => {
            if (task.id === id) pipeline.stage[k1].tasks[k2] = {...task, ...params};
          });
      });
      return {...pipeline};
    });

    if (notify)
      Swal.mixin({toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true})
        .fire({icon: 'success', title: 'Tarefa alterada com sucesso!'});
  };

  const orderTasks = (tasks, reverse = false) => {
    return tasks.sort((a, b) => {
      if (new Date(a.dateStart) > new Date(b.dateStart)) return reverse ? -1 : 1;
      if (new Date(a.dateStart) < new Date(b.dateStart)) return reverse ? 1 : -1;
      return 0;
    });
  };

  const reducePipelineTasks = pipeline => {
    const tasks = [];
    pipeline.stage.forEach(stage => {
      stage.tasks.forEach(task => tasks.push(task));
    });
    return tasks;
  };

  const reduceSubtasks = (tasks, reduce = true) => {
    const allTasks = [];
    tasks.forEach(task => {
      allTasks.push(task);
      if (reduce && task.subTasks) task.subTasks.forEach(subTask => allTasks.push({...subTask, taskMain: task.id}));
    });
    return allTasks;
  };

  const createTask = async () => {
    const {value} = await Swal.fire({icon: "question", title: 'Qual o nome da tarefa?', input: 'textarea', showCancelButton: true, cancelButtonText: 'Cancelar'});
    if (!value) return;

    await api.post('/task', {title: value, pipeline: {id: currentPipeline.id}});
    await Swal.fire({icon: "success", title: 'Tarefa criada com sucesso!'});
    await getPipeline();
  };

  const changeTaskStage = async (idTask, idStage) => {
    let taskToMove;
    let stageKeyFrom = null;
    let taskIndexFrom = null;

    // Atualiza o pipeline no estado local
    setCurrentPipeline(pipeline => {
      const updatedPipeline = structuredClone(pipeline);

      // Localizar tarefa e remover do estágio atual
      updatedPipeline.stage.forEach((stage, stageIndex) => {
        const taskIndex = stage.tasks.findIndex(task => task.id === idTask);
        if (taskIndex !== -1) {
          taskToMove = stage.tasks[taskIndex];
          stageKeyFrom = stageIndex;
          taskIndexFrom = taskIndex;
          stage.tasks.splice(taskIndex, 1); // Remove tarefa
        }
      });

      // Adicionar tarefa ao novo estágio
      const stageTo = updatedPipeline.stage.find(stage => stage.id === idStage);
      if (stageTo && taskToMove) {
        stageTo.tasks.push(taskToMove);
      }

      // Ordenar as tarefas em todos os estágios pela sequência
      updatedPipeline.stage.forEach(stage => {
        stage.tasks.sort((a, b) => a.sequence - b.sequence);
      });

      return updatedPipeline;
    });

    // Atualiza o backend
    try {
      const { data } = await api.put(`/task/${idTask}/stage/${idStage}`);
      changeTask(idTask, { status: data.status }, false, false); // Atualização local com o retorno do backend
    } catch (e) {
      Swal.fire({
        icon: 'warning',
        title: 'Não foi possível atualizar.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });

      // Reverte mudanças locais em caso de erro
      setCurrentPipeline(pipeline => {
        const revertedPipeline = structuredClone(pipeline);
        const stageFrom = revertedPipeline.stage[stageKeyFrom];
        if (taskToMove && stageFrom) {
          stageFrom.tasks.splice(taskIndexFrom, 0, taskToMove); // Reinsere no local original
        }
        return revertedPipeline;
      });
    }
  };

  const handleAddStage = async () => {
    if (newStageName && newStageColor && currentPipeline.id) {
      try {
        // Enviar os dados para o backend
        const response = await api.post('/task/stage', {
              name: newStageName,
              color: newStageColor,
              sequence: currentPipeline.stage.length + 1,
              pipelineId: currentPipeline.id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`, // Envia o token
              },
            }
        );

        // Adiciona o novo stage à pipeline usando os dados retornados pelo backend
        const newStage = response.data;
        setCurrentPipeline((prevPipeline) => {
          const updatedPipeline = { ...prevPipeline };
          updatedPipeline.stage.push({
            id: newStage.id,
            name: newStage.name,
            color: newStage.color,
            sequence: newStage.sequence,
            tasks: [],
          });
          return updatedPipeline;
        });

        // Fechar o modal e resetar os campos
        setIsModalOpen(false);
        setNewStageName('');
        setNewStageColor('#FF0000'); // Cor padrão
      } catch (error) {
        console.error('Erro ao criar o stage:', error);
        alert('Erro ao criar o stage. Por favor, tente novamente.');
      }
    } else {
      alert('Preencha todos os campos.');
    }
  };

  const changeTaskStageNew = async (task, from, to) => {
    const originalCurrentTaskPipelineStage = structuredClone(task);
    // console.log('Antigo:', JSON.stringify(originalCurrentPipelineStage));

    let taskToMove = task;
    let stageKeyFrom = null;
    let taskIndexFrom = null;

    // Atualiza o pipeline no estado local
    setCurrentPipeline(pipeline => {
      const updatedPipeline = structuredClone(pipeline);

      // Localizar tarefa e remover do estágio atual
      updatedPipeline.stage.forEach((stage, stageIndex) => {
        const taskIndex = stage.tasks.findIndex(task => task.id === idTask);
        if (taskIndex !== -1) {
          taskToMove = stage.tasks[taskIndex];
          stageKeyFrom = stageIndex;
          taskIndexFrom = taskIndex;
          stage.tasks.splice(taskIndex, 1); // Remove tarefa
        }
      });

      // Adicionar tarefa ao novo estágio
      const stageTo = updatedPipeline.stage.find(stage => stage.id === idStage);
      if (stageTo && taskToMove) {
        stageTo.tasks.push(taskToMove);
      }

      // Ordenar as tarefas em todos os estágios pela sequência
      updatedPipeline.stage.forEach(stage => {
        stage.tasks.sort((a, b) => a.sequence - b.sequence);
      });

      return updatedPipeline;
    });

    // Atualiza o backend
    try {
      const { data } = await api.put(`/task/${idTask}/stage/${idStage}`);
      changeTask(idTask, { status: data.status }, false, false); // Atualização local com o retorno do backend
    } catch (e) {
      Swal.fire({
        icon: 'warning',
        title: 'Não foi possível atualizar.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });

      // Reverte mudanças locais em caso de erro
      setCurrentPipeline(pipeline => {
        const revertedPipeline = structuredClone(pipeline);
        const stageFrom = revertedPipeline.stage[stageKeyFrom];
        if (taskToMove && stageFrom) {
          stageFrom.tasks.splice(taskIndexFrom, 0, taskToMove); // Reinsere no local original
        }
        return revertedPipeline;
      });
    }
  };


  useEffect(() => {
    getPipelines();
  }, []);

  useEffect(() => {
    if (tab === 1) {
      for (let i = 1; i <= 50; i += 1) {
        const div = document.querySelector(`.react-kanban-board > div > div:nth-child(${i}) > div:nth-child(2)`)
        if (!div) break;
        div.className = 'scroll';
        div.style.overflowX = 'hidden';
        div.style.overflowY = 'auto';
        div.style.height = 'calc(100% - 30px)';
      }
      [...document.getElementsByClassName('react-kanban-column')].forEach(element => {
        if (element.scrollHeight > element.clientHeight)
          element.style.height = ''
      });
    }
  }, [tab, currentPipeline.stage]);

  return (
    <Fragment>
      <Dialog
        open={modalManagerPipeline.open}
        onClose={() => setModalManagerPipeline({ open: false, id: null, name: '', picture: '' })}
        fullWidth
      >
        <DialogTitle>{modalManagerPipeline.id ? 'Editar' : 'Cadastrar'} Pipeline</DialogTitle>
        <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt="Remy Sharp"
            // src="/static/images/avatar/1.jpg"
            style={{ width: 80, height: 80 }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <GridContainer>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Nome"
                type="email"
                fullWidth
                variant="standard"
                onChange={({ target: { value } }) => setModalManagerPipeline({ ...modalManagerPipeline, name: value })}
                value={modalManagerPipeline.name}
              />
            </GridItem>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Tipo de pipeline</InputLabel>
                <Select
                  value={modalManagerPipeline.type}
                  onChange={({ target: { value } }) =>
                    setModalManagerPipeline({ ...modalManagerPipeline, type: value })
                  }
                  fullWidth
                >
                  {/*<MenuItem disabled selected value={'default'}>Selecione um tipo</MenuItem>*/}
                  {[
                    // project, support, marketing, sales
                    { name: 'Gestão de Tarefas', description: '', slug: 'task' },
                    { name: 'Gestão de Leads/Vendas', description: '', slug: 'lead' },
                    { name: 'Gestão de Processos Internos', description: '', slug: 'internal' },
                  ].map(prop => {
                    return (
                      <MenuItem key={prop.slug} value={prop.slug} style={{ display: 'block' }}>
                        <span>{prop.name}</span>
                        <br />
                        <small>
                          <i>{prop.description}</i>
                        </small>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button color={'hust'} onClick={modalManagerPipeline.id ? handleEditPipeline : createPipeline}>
            {modalManagerPipeline.id ? 'Editar' : 'Cadastrar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={refMenuPipelines.current}
        open={openListPipelines}
        onClose={() => setOpenListPipelines(false)}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {pipelines.map(prop => (
          <MenuItem
            selected={prop.id === currentPipeline.id}
            key={prop.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              px: 2,
              py: 1.5,
              borderRadius: 1,
              minWidth: '350px', // Largura ajustada
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
            onClick={() => {
              getPipeline(prop.id);
              setOpenListPipelines(false);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Avatar />
              {prop.name}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {/* Botão de Edição */}
              <Tooltip title="Editar" arrow>
                <Button
                  justIcon
                  simple
                  color="primary"
                  round
                  sx={{
                    width: 30,
                    height: 30,
                    minWidth: 30,
                    padding: 0,
                    '&:hover': { bgcolor: 'primary.light' },
                  }}
                  onClick={e => {
                    setModalManagerPipeline({
                      open: true,
                      id: prop.id,
                      name: prop.name,
                      type: prop.type,
                    });
                  }}
                >
                  <MdOutlineModeEdit />
                </Button>
              </Tooltip>

              {/* Botão de Exclusão */}
              <Tooltip title="Excluir" arrow>
                <Button
                  justIcon
                  simple
                  color="primary"
                  round
                  sx={{
                    width: 30,
                    height: 30,
                    minWidth: 30,
                    padding: 0,
                    '&:hover': { bgcolor: 'primary.light' },
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    handleDeletePipeline(prop.id);
                  }}
                >
                  <MdDeleteOutline />
                </Button>
              </Tooltip>
            </div>
          </MenuItem>
        ))}

        <MenuItem
          onClick={() => {
            setModalManagerPipeline({ open: true, id: null, name: '', picture: '', type: '' });
            setOpenListPipelines(false);
          }}
        >
          <Button
            color={'primary'}
            justIcon
            simple
            round
            style={{ width: 30, height: 30, minWidth: 30, padding: 0, marginLeft: -3 }}
          >
            <FaPlus />
          </Button>
          Criar nova pipeline
        </MenuItem>
      </Menu>
      <ModalCalledHistory open={!!openChatId} id={openChatId} onClose={() => setOpenChatId(null)} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{ display: 'flex' }}>
        {/*++++++++++++++++++++++++++++++++++++++++++++*/}
        <List ref={refMenuPipelines} component="nav" sx={{ bgcolor: 'background.paper' }} style={{ width: 250 }}>
          <ListItemButton
            aria-expanded={openListPipelines ? 'true' : undefined}
            onClick={() => setOpenListPipelines(!openListPipelines)}
          >
            <ListItemText primary={'Acessando'} secondary={currentPipeline.name} />
            {!openListPipelines ? <IoMdArrowDropright /> : <IoMdArrowDropdown />}
          </ListItemButton>
        </List>
        {/*++++++++++++++++++++++++++++++++++++++++++++*/}
        <div style={{ alignContent: 'center' }}>
          <Tabs value={tab}>
            {[
              { slug: 'resumo', label: 'Resumo', icon: MdOutlineDashboard },
              { slug: 'quadro', label: 'Quadro', icon: BsKanban, disabled: false },
              { slug: 'lista', label: 'Lista', icon: CiBoxList },
              { slug: 'cronograma', label: 'Cronograma', icon: FaChartGantt, disabled: false },
            ].map((prop, key) => (
              <Tab
                key={prop.slug}
                disabled={prop.disabled}
                onClick={() => setTab(key)}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <prop.icon />
                    &nbsp;{prop.label}
                  </div>
                }
              />
            ))}
          </Tabs>
        </div>
      </Box>
      {[1, 2, 3].includes(tab) && (
        <GridItem lg={12} md={12} sm={12} xs={12}>
          {/*{props?.usuario?.id_usuario || 'NADA'}*/}
          <TaskViewer
            open={!!openTaskID}
            id={openTaskID}
            onClose={() => {
              setOpenTaskID(null);
              getPipeline();
            }}
          />
          <Button color={'hust'} onClick={createTask} style={{ width: 100, height: 30 }}>
            Criar Tarefa
          </Button>
        </GridItem>
      )}
      {tab === 0 && (
        <GridContainer justify={'center'}>
          <GridItem lg={8} md={10} sm={12} xs={12}>
            <GridContainer>
              {[
                {
                  title: 'Tarefas pendentes',
                  icon: AddToHomeScreen,
                  value: 0,
                  color: 'info',
                  // button: {name: 'Gerenciar Conexões', location: '/conexoes'}
                },
                {
                  title: 'Tarefas em andamento',
                  icon: ListAlt,
                  value: 1,
                  color: 'info',
                  // button: {name: 'Gerenciar Listas', location: '/bulkSend/sendingList'}
                },
                {
                  title: 'Atrasadas',
                  icon: ListAlt,
                  value: 2,
                  color: 'danger',
                  // button: {name: 'Gerenciar Campanhas', location: '/bulkSend/campaign'}
                },
                // {title: 'Total de disparos', icon: SendIcon, value: 3},
              ]
                .filter(el => false)
                .map((prop, key) => (
                  <GridItem lg={3} md={3} sm={6} xs={12} key={key}>
                    <Card>
                      <CardHeader color="primary" icon>
                        <p className={classes.cardCategory} style={{ fontSize: '18px' }}>
                          {prop.title}
                        </p>
                        <br></br>
                        <div stats style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <center>
                            <h3 className={classes.cardTitle} style={{ marginBottom: '-10px', color: primaryColor[0] }}>
                              <b>{pontuacaoNumero(prop.value)}</b> <small></small>
                            </h3>
                            <br />
                            <div className={classes.stats}>
                              &nbsp;
                              {/*Atualizado agora*/}
                            </div>
                          </center>

                          <CardIcon
                            color={prop.color || 'primary'}
                            style={{ width: '50px', height: '50px', float: 'right' }}
                          >
                            <prop.icon style={{ margin: '0 auto' }} />
                          </CardIcon>
                        </div>
                      </CardHeader>
                      <CardFooter>
                        {prop.button && (
                          <Button
                            fullWidth
                            color={'hust'}
                            round
                            simple
                            onClick={() => {
                              //* * *
                            }}
                          >
                            <prop.icon />
                            {prop.button.name}
                          </Button>
                        )}
                      </CardFooter>
                    </Card>
                  </GridItem>
                ))}
              {/*+++++++++++++++++++++++++++*/}
              <GridItem lg={6} md={6} sm={6} xs={12}>
                <Card>
                  <CardHeader>Visão geral do status</CardHeader>
                  <CardBody>
                    <Doughnut
                      data={{
                        labels: currentPipeline.stage.map(prop => prop.name),
                        datasets: [
                          {
                            label: '',
                            data: currentPipeline.stage.map(prop => prop.tasks.length),
                            backgroundColor: currentPipeline.stage.map(prop => `rgba(${hexToRgb(prop.color)}, 0.2)`),
                            borderColor: currentPipeline.stage.map(prop => `rgba(${hexToRgb(prop.color)}, 1)`),
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              {/*<GridItem lg={6} md={6} sm={6} xs={12}>
                <Card>
                  <CardBody>
                    Imagine aqui, a lista de atividades
                  </CardBody>
                </Card>
              </GridItem>*/}
            </GridContainer>
          </GridItem>
        </GridContainer>
      )}
      {tab === 1 && (
        <Board
          allowAddCard
          allowAddColumn
          onCardDragEnd={async (task, from, to) => {
            // TODO - Verifica se mudou de stage
            const originalPipelineStage = structuredClone(currentPipeline); // Clona o estado atual para rollback em caso de erro

            // Decompondo posições
            const { fromPosition, fromColumnId } = from;
            const { toPosition, toColumnId } = to;

            // Verifica se alterou apenas a ordem ou mudou de status
            const onlySequence = fromColumnId === toColumnId;

            // Extraindo tarefas do estágio atual com id e sequência
            let tasksPositionsSource = currentPipeline.stage
              .find(stage => stage.id === fromColumnId)
              .tasks.map(t => ({ id: t.id, sequence: t.sequence, stage: { id: fromColumnId } }));

            let tasksPositionsDestination = currentPipeline.stage
              .find(stage => stage.id === toColumnId)
              .tasks.map(t => ({ id: t.id, sequence: t.sequence, stage: { id: toColumnId } }));

            if (onlySequence) {
              // Ajustando as sequências na mesma coluna
              tasksPositionsSource = tasksPositionsSource.map(prop => {
                if (prop.id === task.id) {
                  // Atualiza diretamente a posição da tarefa movida
                  prop.sequence = toPosition;
                } else if (fromPosition < toPosition) {
                  // Caso mova para frente, desloca para trás as tarefas no intervalo
                  if (prop.sequence > fromPosition && prop.sequence <= toPosition) {
                    prop.sequence -= 1;
                  }
                } else if (fromPosition > toPosition) {
                  // Caso mova para trás, desloca para frente as tarefas no intervalo
                  if (prop.sequence < fromPosition && prop.sequence >= toPosition) {
                    prop.sequence += 1;
                  }
                }
                return prop;
              });
            } else {
              // Ajustando sequências na coluna de origem
              tasksPositionsSource = tasksPositionsSource
                .filter(el => el.id !== task.id) // Remove a tarefa da origem
                .map(prop => {
                  if (prop.sequence > fromPosition) {
                    prop.sequence -= 1; // Reajusta a sequência
                  }
                  return prop;
                });

              // Ajustando sequências na coluna de destino
              tasksPositionsDestination = tasksPositionsDestination.map(prop => {
                if (prop.sequence >= toPosition) {
                  prop.sequence += 1; // Incrementa sequências para abrir espaço
                }
                return prop;
              });

              // Adicionando a tarefa na nova posição
              tasksPositionsDestination.push({ id: task.id, sequence: toPosition, stage: { id: toColumnId } });
            }

            // Atualiza o estado local das tasks no frontend
            setCurrentPipeline(pipeline => {
              const originalTask = pipeline.stage
                .find(el => el.id === fromColumnId)
                .tasks.find(el => el.id === task.id);
              pipeline.stage = pipeline.stage.map(stage => {
                if (stage.id === toColumnId) {
                  if (!onlySequence) stage.tasks.push(originalTask);
                  stage.tasks = stage.tasks
                    .filter(el =>
                      (onlySequence ? tasksPositionsSource : tasksPositionsDestination)
                        .map(prop => prop.id)
                        .includes(el.id),
                    )
                    .map(t => {
                      const { sequence } = (onlySequence ? tasksPositionsSource : tasksPositionsDestination).find(
                        el => el.id === t.id,
                      );
                      t.sequence = sequence; // Atualiza a sequência local
                      return t;
                    });
                }
                if (stage.id === fromColumnId) {
                  stage.tasks = stage.tasks
                    .filter(el => tasksPositionsSource.map(prop => prop.id).includes(el.id))
                    .map(t => {
                      const { sequence } = tasksPositionsSource.find(el => el.id === t.id);
                      t.sequence = sequence; // Atualiza a sequência local
                      return t;
                    });
                }
                return stage;
              });
              return { ...pipeline }; // Retorna o estado atualizado
            });

            try {
              await api.put(`/task/sequence`, [...tasksPositionsSource, ...tasksPositionsDestination]);
            } catch (e) {
              console.log(`Erro ao atualizar a sequência das tasks: ${e.toString()}`);
              // Reverte o estado no caso de erro
              setCurrentPipeline(originalPipelineStage);
            } /**/
          }}
          onColumnDragEnd={async (stage, from, to) => {
            const originalCurrentPipelineStage = structuredClone(currentPipeline);
            // console.log('Antigo:', JSON.stringify(originalCurrentPipelineStage));

            const { fromPosition } = from;
            const { toPosition } = to;

            let stagesPositions = currentPipeline.stage.map(prop => ({ id: prop.id, sequence: prop.sequence }));

            stagesPositions = stagesPositions.map(prop => {
              if (prop.sequence === fromPosition) prop.sequence = toPosition;
              else if (prop.sequence >= toPosition && prop.sequence < fromPosition) prop.sequence += 1;
              else if (prop.sequence >= fromPosition && prop.sequence <= toPosition) prop.sequence -= 1;
              return prop;
            });

            setCurrentPipeline(pipeline => {
              pipeline.stage = pipeline.stage.map(stage => {
                const { sequence } = stagesPositions.find(el => el.id === stage.id);
                stage.sequence = sequence;
                return stage;
              });
              return { ...pipeline };
            });

            try {
              await api.put(`/task/pipeline/sequence`, stagesPositions);
            } catch (e) {
              console.log(`Ocorreu um erro AQUIIII ${e.toString()}`);
              // console.log('Novo:', JSON.stringify(originalCurrentPipelineStage));
              setCurrentPipeline(originalCurrentPipelineStage);
            }
          }}
          // initialBoard={{columns: []}}
          // boards={board}
          renderColumnHeader={({ id, title, color, cards, newStage }) => {
            if (newStage) {
              return (
                <div>
                  {/* Botão para abrir o modal */}
                  <div
                    onClick={() => setIsModalOpen(true)}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '240px',
                      height: '120px',
                      border: '2px dashed #DDD',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease-in-out',
                      backgroundColor: 'transparent',
                    }}
                    onMouseEnter={e => {
                      e.currentTarget.style.backgroundColor = '#F3F3F3';
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                  >
                    <span style={{ fontSize: '24px', color: '#FF0000', fontWeight: 'bold' }}>+</span>
                  </div>

                  {/* Modal */}
                  {isModalOpen && (
                    <div
                      onClick={() => setIsModalOpen(false)} // Fecha o modal ao clicar no fundo
                      style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo escuro semi-transparente
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                      }}
                    >
                      <div
                        style={{
                          background: '#FFF',
                          borderRadius: '8px',
                          padding: '20px',
                          width: '400px',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                        onClick={e => e.stopPropagation()} // Impede que o clique dentro do modal feche a janela
                      >
                        <h3 style={{ marginBottom: '15px', color: '#333' }}>Criar Nova Coluna</h3>

                        {/* Input para o nome da coluna */}
                        <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
                          Nome da Coluna:
                        </label>
                        <input
                          type="text"
                          value={newStageName}
                          onChange={e => setNewStageName(e.target.value)}
                          placeholder="Digite o nome da nova coluna"
                          style={{
                            width: '100%',
                            padding: '8px',
                            marginBottom: '15px',
                            border: '1px solid #CCC',
                            borderRadius: '4px',
                          }}
                        />

                        {/* Input para a cor da coluna */}
                        <label style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
                          Cor da Coluna:
                        </label>
                        <input
                          type="color"
                          value={newStageColor}
                          onChange={e => setNewStageColor(e.target.value)}
                          style={{
                            width: '100%',
                            height: '40px',
                            marginBottom: '15px',
                            border: 'none',
                          }}
                        />

                        {/* Botões de ação */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                          <Button
                              onClick={() => setIsModalOpen(false)}
                              style={{
                                backgroundColor: '#939393',
                                color: '#FFF',
                                padding: '10px 15px',
                                border: 'none',
                                borderRadius: '30px', // Aumentando o arredondamento
                                cursor: 'pointer',
                              }}
                          >
                            Cancelar
                          </Button>
                          <Button
                              color={'hust'}
                              onClick={handleAddStage}
                              style={{
                                color: '#FFF',
                                cursor: 'pointer',
                              }}
                          >
                            Criar Coluna
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
            return (
              <div style={{ fontSize: 11, display: 'flex', placeContent: 'space-between' }}>
                <div style={{ alignContent: 'center' }}>
                  <span
                    style={{
                      padding: '0 5px',
                      background: `rgba(${hexToRgb(color)}, 0.2)`,
                      color: color,
                      borderRadius: 5,
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    {title}
                  </span>
                  <br />
                  <span style={{ color: '#777777', fontWeight: 700 }}>
                    {cards.length}&nbsp;
                    {currentPipeline.type === 'lead' ? 'negociações' : ''}
                    {currentPipeline.type === 'task' ? 'tarefas' : ''}
                    {currentPipeline.type === 'internal' ? 'cards' : ''}
                  </span>
                </div>
                {currentPipeline.type === 'lead' ? (
                  <table style={{ display: 'block', alignContent: 'center' }}>
                    <tr style={{ lineHeight: '14px' }}>
                      <td>Total Único:</td>
                      <td style={{ fontSize: 13 }}>
                        {formatoDinheiro(cards.reduce((total, value) => total + (value.valueUnique || 0), 0))}
                      </td>
                    </tr>
                    <tr style={{ lineHeight: '14px' }}>
                      <td>Total Recorrente:</td>
                      <td style={{ fontSize: 13 }}>
                        {formatoDinheiro(cards.reduce((total, value) => total + (value.valueRecurring || 0), 0))}
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
              </div>
            );
          }}
          renderCard={({ id, title, priority, user }, { dragging }) => (
            <div
              style={{
                margin: '5px 0',
                width: 'calc(270px - 30px)',
                background: '#FFFFFF',
                padding: 5,
                fontFamily: "'Roboto'",
                fontWeight: 500,
                borderRadius: 5,
                position: 'relative',
                display: 'flex',
              }}
            >
              <div style={{ width: '100%' }}>
                <span
                  style={{
                    width: '100%',
                    // display: 'block',
                    lineHeight: 1,

                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2, // Número de linhas desejadas
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                    fontWeight: 100,
                  }}
                >
                  {id} - {title}
                </span>
                <div>
                  {user ? (
                    <ViewUserActions
                      style={{ marginLeft: 0, position: 'absolute', top: 10, right: 5 }}
                      name={user.name}
                      profilePicture={user.profilePicture}
                      showRemove
                      onDelete={async () => {
                        // * * *
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <TaskLabelStatus
                    id={id}
                    simple
                    onChange={params => changeTask(id, params, false, false)}
                    priority={priority}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={'Abrir tarefa'}>
                  <Button
                    style={{ margin: 0, width: 30, height: 30, minWidth: 30, padding: 0 }}
                    justIcon
                    round
                    simple
                    color={'info'}
                    onClick={() => setOpenTaskID(id)}
                    size={'sm'}
                  >
                    <FaRegEdit />
                  </Button>
                </Tooltip>
                <Tooltip title={'Excluir tarefa'}>
                  <Button
                    style={{ margin: 0, width: 30, height: 30, minWidth: 30, padding: 0 }}
                    justIcon
                    round
                    simple
                    color={'danger'}
                    onClick={() => removeTask(id).then(() => getPipeline())}
                    size={'sm'}
                  >
                    <IoClose />
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        >
          {{
            columns: [
              ...currentPipeline.stage
                .sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0))
                .map(prop => ({
                  id: prop.id,
                  title: prop.name,
                  color: prop.color,
                  cards: prop.tasks.sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)),
                })),
              {
                newStage: true,
                cards: [],
              },
            ],
          }}
          {/*{board}*/}
        </Board>
      )}
      {tab === 2 && (
        <TaskManager
          tasks={reducePipelineTasks(currentPipeline)}
          getTasks={() => getPipeline()}
          openTask={setOpenTaskID}
          changeTask={changeTask}
          changeTaskStage={changeTaskStage}
          stages={currentPipeline.stage}
          pipelineId={currentPipeline.id}
        />
      )}
      {tab === 3 && (
        <Fragment>
          <Gantt
            rowHeight={30}
            viewDate={new Date()}
            /*TaskListHeader={({headerHeight}) => {
              return (
                  <div
                    style={{
                      display: 'table-row',
                      listStyle: 'none',
                      height: headerHeight
                    }}
                  >
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>Tarefa</div>
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>Data</div>
                </div>
              );
            }}
            TaskListTable={({rowHeight, tasks, selectedTaskId}) => {
              const {start, name} = tasks.find(el => el.id.toString() === selectedTaskId.toString()) || {};
              return (
                <div
                  style={{
                    display: 'table-row',
                    listStyle: 'none',
                    height: rowHeight
                  }}
                >
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>{name}</div>
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>Data</div>
                </div>
              );
            }}*/
            locale={'pt-br'}
            tasks={reduceSubtasks(
              orderTasks(
                [
                  ...reducePipelineTasks(currentPipeline),
                  { id: 1000, title: '', dateStart: new Date(), dateDue: new Date() },
                ],
                true,
              ),
              false,
            ).map(prop => {
              if (prop.subTasks) console.log(prop);
              return {
                start: new Date(prop.dateStart),
                end: prop.dateDue ? new Date(prop.dateDue) : new Date(prop.dateStart),
                name: prop.title,
                id: prop.id,
                type: 'task',
                dependencies: [prop.taskMain].filter(el => el),
                progress:
                  prop.status === 'finished'
                    ? 100
                    : prop.subTasks
                    ? (prop.subTasks.filter(el => el.status === 'finished').length / prop.subTasks.length) * 100
                    : 0,
                isDisabled: true,
                styles: {
                  progressColor: (prop.status === 'finished' ? successColor : infoColor)[1],
                  progressSelectedColor: (prop.status === 'finished' ? successColor : infoColor)[0],
                },
              };
            })}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Pipeline;