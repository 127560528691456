import React, { useEffect, useState } from 'react';
import { Card, CardBody, GridContainer, GridItem, Table } from "../../components";
import Success from "../../components/Typography/Success";
import Warning from "../../components/Typography/Warning";
import CardHeader from "../../components/Card/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { WebService } from "../../components/WebService";
import {
  hexToRgb,
  infoColor,
  primaryColor,
  successColor,
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import { getFotoLink } from "../../components/Funcoes";
import moment from "moment";
import ModalCalledHistory from "../Modal/CalledHistory";
import { Bar, HorizontalBar, defaults, Doughnut, Radar } from "react-chartjs-2";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/CustomButtons/Button";

defaults.global.defaultFontColor = "#000";
const useStyles = makeStyles(styles);

const colors = {
  'atendimento': `rgba(${hexToRgb(successColor[1])}, 0.5)`,
  'atendimento_pendente': `rgba(${hexToRgb(warningColor[1])}, 0.5)`
};

const RealTime = () => {
  const classes = useStyles();

  const [ departments, setDepartments ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortByLastMessage, setSortByLastMessage] = useState(false);
  const [ openCalledHistory, setOpenCalledHistory ] = useState(null);
  const [ attendanceChartFilter, setAttendanceChartFilter ] = useState('all');
  const [ showCharts, setShowCharts ] = useState(false);

    const getDashData = async () => {
        const response = await WebService(`/dashboard/getDashboardRealtime`);
        if (response.ok) {
            setDepartments(response.departments);
            setUsers(response.users);
            updateFilteredUsers(response.users);
        }
    };

    useEffect(() => {
    let interval = setInterval(getDashData, 20 * 1000);
    getDashData();
    return () => clearInterval(interval);
  }, [sortByLastMessage]);

    // Função para atualizar os usuários filtrados
    const updateFilteredUsers = (usersToFilter) => {
        let updatedUsers = [...usersToFilter];

        updatedUsers.forEach(user => {
            user.calleds.sort((a, b) => {
                const dateA = new Date(a.lastMessageDate || 0);
                const dateB = new Date(b.lastMessageDate || 0);
                return sortByLastMessage ? dateB - dateA : dateA - dateB; // Ordena mensagens
            });
        });

        updatedUsers = updatedUsers.filter(user => {
            if (attendanceChartFilter === 'all') return true;
            return user.calleds.some(call => call.department === attendanceChartFilter); // Filtra por departamento
        });

        setFilteredUsers(updatedUsers);
    };

    // Atualiza os dados ao clicar nos botões
    useEffect(() => {
        updateFilteredUsers(users);
    }, [sortByLastMessage]);

  return (
    <div>
      <ModalCalledHistory open={!!openCalledHistory} id={openCalledHistory} onClose={() => setOpenCalledHistory(null)}/>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Button color={'primary'} round onClick={() => setShowCharts(!showCharts)}>
            {showCharts ? 'Esconder' : 'Mostrar'} Gráficos
          </Button>
        </GridItem>

        {showCharts && (
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <Card>
              {/*<CardBody style={{minHeight: users.length * 40}}>*/}
              <CardBody>
                <FormControl style={{ minWidth: 150 }}>
                  <InputLabel>Filtrar por</InputLabel>
                  <Select
                    value={attendanceChartFilter}
                    onChange={({ target: { value } }) => setAttendanceChartFilter(value)}
                    style={{ textAlign: 'left' }}
                    // fullWidth
                  >
                    <MenuItem value={'all'}>Todos</MenuItem>
                    {departments.map((prop, key) => (
                      <MenuItem value={prop.name} key={`dpt_${key}`}>{prop.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ minHeight: users.length * 40 }}>
                  <HorizontalBar
                    data={{
                      // !!!!!!!!! Código de comparação cachorro e redundante !!!!!!!!!
                      labels: filteredUsers
                        .filter(el => {
                          if (attendanceChartFilter === 'all') return true;
                          return (el.calleds.filter(el1 => el1.department === attendanceChartFilter).length);
                        })
                        .sort((a, b) => {
                          const aAttendance = a.calleds.filter(el => el.state === 'atendimento').length;
                          const bAttendance = b.calleds.filter(el => el.state === 'atendimento').length;
                          return aAttendance < bAttendance ? 1 : (aAttendance > bAttendance) ? -1 : 0;
                        })
                        .map(prop => prop.name),
                      datasets: [
                        {
                          label: 'Em Atendimento',
                          data: filteredUsers.filter(el => {
                            if (attendanceChartFilter === 'all') return true;
                            return (el.calleds.filter(el1 => el1.department === attendanceChartFilter).length);
                          }).sort((a, b) => {
                            const aAttendance = a.calleds.filter(el => el.state === 'atendimento').length;
                            const bAttendance = b.calleds.filter(el => el.state === 'atendimento').length;
                            return aAttendance < bAttendance ? 1 : (aAttendance > bAttendance) ? -1 : 0;
                          }).map(prop => {
                            prop.calleds = prop.calleds.filter(el => {
                              if (attendanceChartFilter === 'all') return true;
                              return el.department === attendanceChartFilter;
                            });
                            return prop.calleds.filter(el => el.state === 'atendimento').length;
                          }),
                          backgroundColor: `rgba(${hexToRgb(successColor[0])}, 0.2)`,
                          borderColor: `rgba(${hexToRgb(successColor[0])}, 0.2)`,
                          hoverBackgroundColor: `rgba(${hexToRgb(successColor[0])}, 0.8)`,
                          hoverBorderColor: `rgba(${hexToRgb(successColor[0])}, 1)`,
                          borderWidth: 1,
                        },
                        {
                          label: 'Em Espera',
                          data: filteredUsers.filter(el => {
                            if (attendanceChartFilter === 'all') return true;
                            return (el.calleds.filter(el1 => el1.department === attendanceChartFilter).length);
                          }).sort((a, b) => {
                            const aAttendance = a.calleds.filter(el => el.state === 'atendimento').length;
                            const bAttendance = b.calleds.filter(el => el.state === 'atendimento').length;
                            return aAttendance < bAttendance ? 1 : (aAttendance > bAttendance) ? -1 : 0;
                          }).map(prop => {
                            prop.calleds = prop.calleds.filter(el => {
                              if (attendanceChartFilter === 'all') return true;
                              return el.department === attendanceChartFilter;
                            });
                            return prop.calleds.filter(el => el.state === 'atendimento_pendente').length;
                          }),
                          backgroundColor: `rgba(${hexToRgb(warningColor[0])}, 0.2)`,
                          borderColor: `rgba(${hexToRgb(warningColor[0])}, 1)`,
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      indexAxis: 'y',
                      plugins: {
                        title: { display: false, text: 'Teste', font: { size: 12, family: 'rubik' } },
                        legend: { display: false, position: 'right' }
                      },
                      maintainAspectRatio: false
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {showCharts && (
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <Card>
              <CardBody>
                <div style={{ minHeight: departments.length * 40 }}>
                  <HorizontalBar
                    data={{
                      labels: departments.map(prop => prop.name.slice(0, 30)),
                      datasets: [
                        {
                          label: 'Em Atendimento',
                          data: departments.map(prop => prop.numberExecution),
                          backgroundColor: `rgba(${hexToRgb(successColor[0])}, 0.2)`,
                          borderColor: `rgba(${hexToRgb(successColor[0])}, 1)`,
                          borderWidth: 1,
                        },
                        {
                          label: 'Em Espera',
                          data: departments.map(prop => prop.numberAwaiting),
                          backgroundColor: `rgba(${hexToRgb(warningColor[0])}, 0.2)`,
                          borderColor: `rgba(${hexToRgb(warningColor[0])}, 1)`,
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
                {/*<Doughnut
                  data={{
                    labels: departments.map(prop => prop.name),
                    datasets: [
                      {
                        label: 'Em Espera',
                        data: departments.map(prop => prop.numberAwaiting),
                        backgroundColor: users.map((prop, key) => `rgba(${hexToRgb(colors[key % colors.length][0])}, 0.2)`),
                        borderColor: users.map((prop, key) => `rgba(${hexToRgb(colors[key % colors.length][0])}, 1)`),
                        borderWidth: 1,
                      },
                      {
                        label: 'Em Atendimento',
                        data: departments.map(prop => prop.numberExecution),
                        backgroundColor: users.map((prop, key) => `rgba(${hexToRgb(colors[key % colors.length][0])}, 0.2)`),
                        borderColor: users.map((prop, key) => `rgba(${hexToRgb(colors[key % colors.length][0])}, 1)`),
                        borderWidth: 1,
                      },
                    ]
                    // labels: ['Running', 'Swimming', 'Eating', 'Cycling'],
                    // datasets: [
                    //   {
                    //     data: [ 20, 10, 4, 2 ]
                    //   }
                    // ]
                  }}
                />*/}
              </CardBody>
            </Card>
          </GridItem>
        )}
        <GridItem lg={6} md={6} sm={6} xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Atendimentos por departamento</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[ "", "Departamento", "Em execução", "Em espera" ]}
                hover
                tableData={
                  departments
                    .map((prop, key) => {
                      const { name, numberAwaiting, numberExecution } = prop;
                      return [
                        key + 1,
                        name,
                        (
                          <span style={{
                            fontWeight: numberExecution ? 800 : null,
                            color: numberExecution ? successColor[0] : null
                          }}>
                        {numberExecution}
                      </span>
                        ),
                        (
                          <span style={{
                            fontWeight: numberAwaiting ? 800 : null,
                            color: numberAwaiting ? warningColor[0] : null
                          }}>
                        {numberAwaiting}
                      </span>
                        )
                      ];
                    })
                }
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={6} md={6} sm={6} xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Atendimentos por usuário</h4>
            </CardHeader>
              <CardBody>
              <Table
                tableHead={[ "", "Departamento", "Em execução", "Em espera" ]}
                hover
                tableData={
                    filteredUsers
                    .map((prop, key) => {
                      const { name, numberAwaiting, numberExecution } = prop;
                      return [
                        key + 1,
                        name,
                        (
                          <span style={{
                            fontWeight: numberExecution ? 800 : null,
                            color: numberExecution ? successColor[0] : null
                          }}>
                        {numberExecution}
                      </span>
                        ),
                        (
                          <span style={{
                            fontWeight: numberAwaiting ? 800 : null,
                            color: numberAwaiting ? warningColor[0] : null
                          }}>
                        {numberAwaiting}
                      </span>
                        )
                      ];
                    })
                }
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
            <GridItem lg={12} md={12} sm={12} xs={12}>
                <Button
                    color={!sortByLastMessage ? 'hust' : 'default'}
                    round
                    onClick={() => setSortByLastMessage(false)}
                >
                    Ordenar por Data de Abertura
                </Button>
                <Button
                    color={sortByLastMessage ? 'hust' : 'default'}
                    round
                    onClick={() => setSortByLastMessage(true)}
                >
                    Ordenar por Última Mensagem
                </Button>
            </GridItem>
          <GridContainer>
            {filteredUsers.map(prop => {
              return (
                <GridItem lg={3} md={3} sm={4} xs={12}>
                  <Card style={{ maxHeight: 460, height: '100%' }}>
                    <CardBody>
                      <h3 style={{ margin: 0 }}>{prop.name}</h3>
                      <div style={{ marginBottom: -20 }}>
                        <span><strong>Espera:</strong> {prop.calleds.filter(el => el.state === 'atendimento_pendente').reduce(a => a + 1, 0)}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span><strong>Em atendimento:</strong> {prop.calleds.filter(el => el.state === 'atendimento').reduce(a => a + 1, 0)}</span>
                      </div>
                      <hr/>
                      <div style={{ overflowY: 'auto', maxHeight: 355, overflowX: 'hidden' }}>
                        {prop.calleds.map(prop1 => {
                          const { protocol, contact: { name, phone, picture }, dateStart, department, state } = prop1;
                          const tempoAtendimento = moment(new Date(dateStart)).from(new Date()).split('há ').join("");

                          return (
                            <div
                              style={{
                                padding: 5,
                                backgroundColor: colors[state] || '#EEE',
                                marginBottom: 5,
                                borderRadius: 10,
                                cursor: 'pointer'
                              }}
                              onClick={() => setOpenCalledHistory(protocol)}
                            >
                              <img
                                alt={name || phone}
                                src={getFotoLink(picture)}
                                style={{ width: 30, borderRadius: '50%', marginRight: 5 }}
                              />
                              <span style={{ fontWeight: 800 }}> {protocol}</span> - {name}<br/>
                              <small><strong>Telefone: </strong>{phone}</small>{' '}<br/>
                              <small><strong>Departamento: </strong>{department}</small><br/>
                              <small><strong>Tempo de atendimento: </strong>{tempoAtendimento}</small>
                            </div>
                          );
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default RealTime;