import React, { useEffect, useState, useRef } from "react";
import cx from "classnames";
import { Switch, Redirect } from "react-router-dom";
import Stories from "react-insta-stories";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import blue from "@material-ui/core/colors/blue";
import routes from "../routes.js";

import styles from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import {isHustDomain, isLocalhost, ls} from "../components/Funcoes";
import { configHost, LoadRouterProps } from "../components/WebService";
import { apiURL, convertDate, WebService } from "../components/WebService";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import Draggable from "../components/Draggable";
import {
  primaryColor,
  successColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import Swal from "sweetalert2";
import { checkBeta } from "../views/BetaControll";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [menuButton, setMenuButton] = useState(false);
  const [image, setImage] = useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = useState("primary");
  const [bgColor, setBgColor] = useState("black");
  const [webSocket, setWebSocket] = useState(null);
  const [fixedClasses, setFixedClasses] = useState("dropdown");
  const [nomeCliente, setNomeCliente] = useState("");
  const [logo, setLogo] = useState(require("../assets/img/logo-white.svg"));
  const classes = useStyles();
  const ref = useRef(null);

  const menuButtonColor = blue[700];
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  const mainPanel = React.createRef();

  const conectarWebSocket = () => {
    let interval;
    const conn = new WebSocket(
      `ws${configHost.protocol}://${configHost.baseURL}:${configHost.portWSS}`
    );
    conn.onopen = function() {
      console.log("Conexão realizada");
      conn.send(JSON.stringify({ token: ls("token"), metodo: "login" }));
      interval = setInterval(() => {
        conn.send(JSON.stringify({ metodo: "ping" }));
      }, 20 * 1000);
    };

    conn.addEventListener("message", function({ data }) {
      data = JSON.parse(data);
      const { autenticado, mensagemCarregando } = data;
      if (autenticado) setWebSocket(conn);
      /*if (mensagemCarregando) {
        if (document.getElementById("labelCarregando"))
          document.getElementById("labelCarregando").innerText = mensagemCarregando;
        localStorage.setItem("ultimaMensagemCarregando", mensagemCarregando);
      }*/
    });

    conn.onclose = function(e) {
      console.log(
        `Socket foi finalizado. Reconectando em 1 segundo. [${e.reason}]`
      );
      clearInterval(interval);
      setTimeout(conectarWebSocket, 1000);
    };

    conn.onerror = function(err) {
      console.error(`Socket encountered error: ${err.message} Closing socket`);
      conn.close();
    };
  };

  Draggable(ref);

  useEffect(() => {
    conectarWebSocket();
    // getNomes();
    getFeatures();
  }, []);

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) ps.destroy();
      window.removeEventListener("resize", resizeFunction);
    };
  });

  // functions for changeing the states from components
  const handleImageClick = setImage;
  const handleColorClick = setColor;

  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };

  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    // window.location.pathname.indexOf("/empresas/") === -1
    return true;
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Macrochat";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.collapse) return getRoutes(prop.views);

      return prop.layout === "/admin" &&
        (!rest.gestor ? prop.allUsers : true) &&
        (prop.revenda ? rest.revenda : true) ? (
        <LoadRouterProps
          path={prop.path}
          component={prop.component}
          key={key}
          assets={props.assets}
          webSocket={webSocket}
          setMobileOpen={setMobileOpen}
          usuario={rest.usuario}
        />
      ) : null;
    });

  const [storiesSize, setStoriesSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resize = () => {
      setStoriesSize((ss) => {
        ss.width = (window.innerHeight / 16) * 9;
        ss.height = window.innerHeight;

        if (ss.width > window.innerWidth) {
          ss.width = window.innerWidth;
          ss.height = (window.innerWidth / 9) * 16;
        }

        return { ...ss };
      });
    };

    resize();
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const mobMenuToogle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    //setMiniActive(window.location.pathname === "/chat");
    resizeFunction();
  }, [window.location.pathname]);

  const resizeFunction = () => {
    if (window.innerWidth >= 1400) {
      ["/chat", "/bot_novo", '/crm'].includes(window.location.pathname)
        ? setMiniActive(true)
        : setMiniActive(false);
      setMenuButton(false);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 960) {
      setMiniActive(true);
      setMenuButton(false);
    } else if (window.innerWidth < 960) {
      setMiniActive(false);
      if (rest.gestor) setMenuButton(true);
    }
  };

  const fullScreen = ["/chat", "/bot_novo", '/crm'].includes(window.location.pathname);

  const getFeatures = async () => {
    const token = ls("token");
    if (!token) return;

    const { ok, features } = await WebService("/sistema/checkFeatures");
    if (!ok) return;

    let refresh = false;
    Object.keys(features).forEach((prop) => {
      if (checkBeta(prop) !== features[prop].enabled) {
        localStorage.setItem(`flag-${prop}`, features[prop].enabled.toString());
        refresh = true;
      }
    });
    if (refresh) window.location.reload();
  };

  const getNomes = async () => {
    const { ok, nome, nomeCliente } = await WebService(
      `/usuario/getDadosEmpresa`,
      { token: ls("token") }
    );
    if (ok) {
      setNomeCliente(nomeCliente);
    }
  };

  const getStories = async () => {
    const { ok, stories: s } = await WebService(`/stories/getSystemStories`, {}, 'GET', false);
    if (ok) {
      // Realiza gestão do cache da mídia
      // *********************************************
      if ('caches' in window) {
        const cache = await caches.open('stories');
        for (let i = 0; i < s.length; i += 1) {
          try {
            const { uuid } = s[i];
            const url = `https://storage.hustapp.com/stories/${uuid}`;
            const resposta = await cache.match(url);
            if (!resposta) await cache.add(url);
          } catch (e) {
            console.log('Ocorreu um erro ao salvar o cache da mídia');
          }
        }
      }
      // *********************************************
      setStories({
        ...stories,
        media: s.map(prop => ({
          id: prop.id_sistema_stories,
          url: `https://storage.hustapp.com/stories/${prop.uuid}`,
          type: prop.type,
          seeMore: () => '',
          seeMoreCollapsed: ({ toggleMore, action }) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60px',
                  width: '140px',
                  background: 'transparent',
                  color: '#FF3115',
                  fontSize: '18px',
                  marginBottom: '20px',
                  border: '2px solid #FF0000',
                  borderRadius: '25px',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  position: 'relative',
                  overflow: 'hidden',
                }}
                onMouseEnter={e => {
                  e.currentTarget.style.background = 'linear-gradient(45deg, #FF3115, #FC2E5D)';
                  e.currentTarget.style.border = '2px solid #FFF';
                  e.currentTarget.style.fontWeight = 'bold';
                  e.currentTarget.style.color = '#FFF';
                  e.currentTarget.style.boxShadow = '0 4px 15px rgba(255, 69, 0, 0.4)';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.background = 'transparent';
                  e.currentTarget.style.border = '2px solid #FF3115';
                  e.currentTarget.style.fontWeight = 'normal';
                  e.currentTarget.style.color = '#FF3115';
                  e.currentTarget.style.boxShadow = 'none';
                }}
                onMouseDown={e => {
                  e.currentTarget.style.transform = 'scale(0.95)';
                }}
                onMouseUp={e => {
                  e.currentTarget.style.transform = 'scale(1)';
                }}
                onClick={() => window.open('https://www.instagram.com/hust.app/', '_blank')}
              >
                Seguir 🚀
              </button>
            </div>
          ),
        })),
      });
    }
  };

  useEffect(() => {
    getStories();

    const interval = setInterval(() => {
      getStories();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const [stories, setStories] = useState({
    open: false,
    media: [],
    unreadStories: [],
  });

  const setVisualizedStatus = id => {
    const visualizedStories = JSON.parse(localStorage.getItem(`user-stories`) || '[]');

    if (!visualizedStories.includes(id)) {
      visualizedStories.push(id);
      localStorage.setItem(`user-stories`, JSON.stringify(visualizedStories));
      const { unreadStories } = stories;
      unreadStories.splice(unreadStories.indexOf(id), 1);
      setStories({ ...stories, unreadStories: [...unreadStories] });
    }
    WebService(`/stories/setStoriesView`, { id_sistema_stories: id });
  };

  useEffect(() => {
    const visualizedStories = JSON.parse(localStorage.getItem(`user-stories`) || '[]');
    const unreadStories = [];
    for (let i = 0; i < stories.media.length; i += 1) {
      const { id } = stories.media[i];
      if (!visualizedStories.includes(id)) unreadStories.push(id);
    }

    setStories({ ...stories, unreadStories: [...unreadStories] });
  }, [stories.media]);

  return (
      <div className={classes.wrapper}>
        {stories.open ? (
            <div
                style={{
                  position: "absolute",
                  zIndex: 9999999999,
                  width: " 100%",
                  height: "100%",
                }}
            >
              <Stories
                  stories={stories.media}
                  storyContainerStyles={{
              position: "absolute",
              zIndex: 9999,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: "auto",
            }}
            defaultInterval={4000}
            width={storiesSize.width}
            height={storiesSize.height}
            keyboardNavigation={true}
            onAllStoriesEnd={() => setStories({ ...stories, open: false })}
            onStoryStart={(key, { id }) => setVisualizedStatus(id)}
            // renderers={[
            //   {tester: () => ({condition: true, priority: 9}), renderer: () => <span>Top</span>}
            // ]}
          />
          <div
            style={{
              backgroundColor: "#000",
              width: " 100%",
              height: "100%",
              position: "absolute",
              opacity: 0.9,
            }}
            onClick={() => setStories({ ...stories, open: false })}
          />
        </div>
      ) : (
        ""
      )}
      {/***************/}
      {(isHustDomain || isLocalhost) ? (
        <div
          id={"stories-view-main-open"}
          style={{
            visibility: "visible",
            position: "absolute",
            background: "#FF3115",
            right: 0,
            // right: -15,
            bottom: 150,
            borderRadius: 15,
            zIndex: 999999999,
          }}
        >
          {stories.unreadStories.length ? (
            <span
              style={{
                position: "absolute",
                zIndex: 99999999,
                top: -10,
                left: -5,
                background: successColor[0],
                color: "white",
                fontWeight: 900,
                width: 20,
                height: 20,
                textAlign: "center",
                borderRadius: "50%",
              }}
            >
              {stories.unreadStories.length}
            </span>
          ) : (
            ""
          )}
          <Fab
            type="button"
            onClick={() => {
              if (!stories.media.length)
                return Swal.fire({
                  icon: "info",
                  title: "Nenhum novo Storie disponível",
                });
              setStories({ ...stories, open: true });
            }}
            style={{
              backgroundColor: "#015874",
              borderRadius: "5px 0px 0px 5px",
              backgroundImage: `url('/MacrochatImages/MacrochatLogo.png')`,
              backgroundSize: "calc(100% + 5px)",
              backgroundPosition: "center",
            }}
          />
        </div>
      ) : (
        ""
      )}
      {!window.isNativeApp ? (
        <div
          className={classes.float}
          ref={ref}
          style={{ visibility: menuButton ? "visible" : "hidden" }}
        >
          <Fab
            type="button"
            onClick={() => mobMenuToogle()}
            style={{ backgroundColor: primaryColor[0] }}
          >
            <MenuIcon style={{ color: "white" }}/>
          </Fab>
        </div>
      ) : ''}
      {!window.isNativeApp && rest.gestor && (
        <Sidebar
          routes={routes.filter((el) => (el.revenda ? rest.revenda : true))}
          logoText={rest.assets.nomeAplicacao}
          nomeEmpresa={nomeCliente}
          logo={rest.assets.logo}
          image={rest.assets.fundoMenu}
          logoNome={rest.assets.nomeLogo}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
      )}
      <div
        className={mainPanelClasses}
        ref={mainPanel}
        style={{ width: !rest.gestor ? "100%" : undefined }}
      >
        {}
        {getRoute() && !fullScreen ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to={rest.gestor ? "/dashboard" : "/chat"} />
              </Switch>
            </div>
          </div>
        ) : (
          <div
            className={classes.map}
            style={{ margin: fullScreen ? 0 : undefined }}
          >
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/" to={rest.gestor ? "/dashboard" : "/chat"} />
            </Switch>
          </div>
        )}
        {getRoute() && !fullScreen ? (
          <Footer fluid assets={props.assets} />
        ) : null}
      </div>
    </div>
  );
}
